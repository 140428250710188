div.course_box_login{
    /* NÃO APAGAR FUNDO NETFLIX */
    /* background: linear-gradient(to left, rgba(21, 21, 21, .1) 50%, rgb(0, 0, 0)), 
    linear-gradient(rgba(21, 21, 21, .3) 80%, rgb(0, 0, 0)), 
    url('../../../assets/imgs/laboratory/login-lab-bgimg.jpg') right center / cover; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: relative;
}

div.crs_box_logt{
    position: absolute;
    left: 30px;
    top: 50px;
}

div.crs_box_logt > img{
    cursor: pointer;
}

div.crs_box_intro_lgn{
    background: var(--plat-eleven);
    width: 100%;
    border-radius: 8px;
    padding: 50px 30px;
    border: 1px solid #222;
    margin: 0px 15px;
}

div.crs_box_intro_lgn div.crs_title_lgn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 60px;
}

div.crs_box_intro_lgn div.crs_title_lgn h3{
    font-size: 22px;
    color: var(--plat-six);
}

div.crs_box_intro_lgn div.box_account_forget{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

div.crs_box_intro_lgn span.forget{
    color: var(--plat-seven) !important;
}

/*Media Queries*/
@media (min-width: 550px) {
    div.crs_box_logt{
        left: 50px;
    }

    div.crs_box_intro_lgn{
        width: 425px;
        margin: 0px 0px;
    }
}