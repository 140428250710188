div.admin_content_center div.admin_cont_title{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;
}

div.admin_cont_title div.admin_tit h1{
    color: var(--plat-six);
    font-size: 26px;
}

div.admin_cont_title div.admin_tit p{
    font-size: 18px;
    color: var(--plat-seven);
    margin-bottom: 20px;
}

div.admin_cont_title div.admin_link_back{
    margin-bottom: 10px;
    color: var(--plat-seven);
    font-size: 16px;
}

div.admin_cont_title div.admin_tit a.admin_link_from{
    color: var(--first-color);
    text-decoration: none;
}

div.admin_cont_title div.admin_tit a.admin_link_from:hover{
    text-decoration: underline;
}

@media (min-width: 600px) {
    div.admin_content_center div.admin_cont_title{
        flex-direction: row;
        
    }

    div.admin_cont_title div.admin_tit p{
        margin-bottom: 0;
    }
}