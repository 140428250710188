div.main_merchan{
    width: 100%;
    border-bottom: 1px solid var(--plat-two);
    display: flex;
    padding: 50px 15px 50px 15px;
    margin-top: 100px;
}

div.main_merchan.alert-active{
    margin-top: 145px;
}

div.main_merchan div.mer_content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

div.main_merchan div.mer_content div.mercontbottom{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
}

div.mer_content div.merconttop h1{
    color: var(--plat-six);
    font-size: 26px;
    margin-bottom: 15px;
}

div.mer_content div.merconttop p{
    font-size: 18px;
    color: var(--plat-seven);
}

div.mer_content div.merconttop p.mrg_bot {
    margin-bottom: 35px;
}

div.mer_content div.merconttop span.color_green{
    color: var(--first-color);
}

div.mercontbottom .btn_student_header svg{
    transition: all .3s;
}

div.mercontbottom .btn_student_header:hover svg{
    color: var(--plat-two);
}

/*Media Queries*/
@media (min-width: 541px) {
    div.main_merchan div.mer_content div.mercontbottom{
        flex-direction: row;
        margin-top: 100px;
    }

    div.mer_content div.merconttop h1{
        font-size: 28px;
    }
}

@media (min-width: 550px) {
    div.main_merchan{
        margin-top: 60px;
    }

    div.main_merchan.alert-active{
        margin-top: 100px;
    }
}

@media (min-width: 650px) {
    div.main_merchan div.mer_content{
        width: 85%;
    }

    div.mer_content div.merconttop h1{
        font-size: 32px;
    }
}

@media (min-width: 990px) {
    div.main_merchan{
        padding: 100px 30px;
    }

    div.main_merchan div.mer_content{
        width: 75%;
    }

    div.mer_content div.merconttop h1{
        font-size: 36px;
    }
}

@media (min-width: 1100px) {
    div.main_merchan{
        padding-left: 60px;
        padding-right: 60px;
    }

    div.main_merchan div.mer_content{
        width: 65%;
    }

    div.mer_content div.merconttop h1{
        font-size: 38px;
    }

    div.mer_content div.merconttop p{
        font-size: 20px;
    }
}

@media (min-width: 1300px) {
    div.mer_content div.merconttop h1{
        font-size: 40px;
    }
}

@media (min-width: 1400px) {
    div.main_merchan div.mer_content{
        width: 50%;
    }

    div.mer_content div.merconttop h1{
        font-size: 42px;
    }
}