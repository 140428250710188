div.box_blog,
div.box_blog_art{
    padding: 0px 15px;
    margin: 165px 0px 40px 0px;
}

div.box_blog.alert-active,
div.box_blog_art.alert-active{
    margin: 210px 0px 40px 0px;
}

div.box_blog div.blog_content{
    width: 100%;
    margin-bottom: 40px;
}

div.blog_content div.blog_section_1, 
div.blog_content div.blog_section_3{
    width: 100%;
}

div.blog_content div.blog_section_3, 
div.blog_section_3 article{
    display: flex;
    flex-direction: column;
}

div.blog_section_1 > article, 
div.blog_section_3 article{
    width: 100%;
    transition: all .3s;
    position: relative;
    background: var(--plat-two);
    border: 1px solid var(--plat-four);
    /* border-top: 1px solid var(--first-color); */
    box-shadow: 0px 3px 6px var(--shadow-color);
    border-radius: 4px;
    padding: 5px 5px 15px 5px;
    margin-bottom: 50px;
}

div.blog_section_1 > article > a, 
div.blog_section_3 article > a{
    display: flex;
    cursor: pointer;
    position: relative;
    width: 100%;
}

div.article_bg_img,
div.blog_section_3 article div.article_bg_img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    height: 200px;
    width: 100%;
    margin-bottom: 12px;
    opacity: .85;
}

div.blog_section_3 article div.article_bg_img{
    margin-bottom: 0px;
}

div.blog_section_1 > article:hover div.article_bg_img,
div.blog_section_3 article:hover div.article_bg_img{
    opacity: 1;
}

div.blog_section_1 > article div.article_content, 
div.blog_section_3 article div.article_content{
    width: 100%;
    padding: 0px 5px;
}

div.blog_section_3 article div.article_content{
    margin-top: 5px;
}

div.blog_section_1 > article div.article_content div.thumb_title,
div.blog_section_3 article div.article_content div.thumb_title{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

div.blog_section_1 > article div.article_content div.thumb_title div.thumb_title_box,
div.blog_section_3 article div.article_content div.thumb_title div.thumb_title_box{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

div.blog_section_3 article div.article_content div.thumb_title div.thumb_title_box h1 a{
    text-decoration: none;
}

div.blog_section_1 > article div.article_content div.thumb_title div.thumb_title_box div.title_box,
div.blog_section_3 article div.article_content div.thumb_title div.thumb_title_box div.title_box{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

div.blog_section_1 > article div.article_content div.thumb_title div.thumb_title_box div.thumb_box img,
div.blog_section_3 article div.article_content div.thumb_title div.thumb_title_box div.thumb_box img,
div.blog_section_1 > article div.article_content div.thumb_title div.thumb_title_box div.thumb_box svg,
div.blog_section_3 article div.article_content div.thumb_title div.thumb_title_box div.thumb_box svg{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    box-shadow: 0px 3px 6px var(--shadow-color);
    border-radius: 50%;
}

div.blog_section_1 > article div.article_content div.thumb_title a.categlk,
div.blog_section_3 article div.article_content div.thumb_title a.categlk{
    padding: 4px 10px;
    margin-right: 15px;
    flex-shrink: 0;
}

div.blog_content_article div.article_content a.categlk{
    padding: 4px 10px;
    margin: 0px 0px 0px 15px;
    flex-shrink: 0;
}

p.notifsubtitle{
    font-size: 16px;
    color: var(--plat-seven);
}

div.blog_section_1 > article div.article_content div.description_article p{
    font-size: 18px;
    margin: 20px 0px 0px 0px;
    color: var(--plat-seven);
}

div.blog_section_full > div.section_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--plat-six);
    padding-top: 40px;
    margin: 40px 0px 20px 0px;
}

div.blog_section_full:nth-child(1) > div.section_title{
    border-top: unset;
    padding-top: unset;
    margin-top: unset;
}

div.blog_section_full > div.section_title > h1{
    font-size: 20px;
    color: var(--plat-two);
    text-transform: uppercase;
}

div.blog_section_full > div.section_title > a {
    color: var(--first-color);
    text-decoration: none;
}

div.blog_section_full > div.section_title > a:hover {
    text-decoration: underline;
}

div.blog_section_3 article div.article_content{
    margin-top: 15px;
}

div.blog_content_article div.article_content span.categbox{
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

div.blog_section_1 > article div.article_content a, 
div.blog_section_3 article div.article_content a,
div.blog_content_article div.article_content span.categbox a{
    border-radius: 3px;
    color: var(--plat-six);
    text-decoration: none;
    display: inline-block;
}

div.blog_section_1 > article div.article_content a:hover, 
div.blog_section_3 article div.article_content a:hover,
div.blog_content_article div.article_content span.categbox a:hover{
    opacity: .95;
}

div.blog_section_1 > article div.article_content span.pub_date, 
div.blog_section_3 article div.article_content span.pub_date,
div.blog_content_article div.article_content span.pub_date,
div.crs_content div.ct_mid_descp,
div.course_title span.pub_date,
div.crs_header span.pub_date{
    font-size: 14px;
    color: var(--plat-twelve);
}

div.course_title span.pub_date,
div.crs_header span.pub_date{
    font-size: 16px;
}

div.blog_content_article div.article_content span.pub_date{
    margin-bottom: 5px;
}

div.blog_section_1 > article div.article_content h1 a, 
div.blog_section_3 article div.article_content h1 a,
div.blog_content_article div.article_content h1 a,
h1.notiftitle{
    font-weight: 600;
    width: 100%;
    display: block;
    text-decoration: none;
}

div.blog_section_1 > article div.article_content h1 a,
div.blog_section_3 article div.article_content h1 a{
    color: var(--plat-six);
    font-size: 20px;
    margin-bottom: 10px;
}

h1.notiftitle{
    color: var(--plat-six);
    font-size: 16px;
}

div.blog_content_article div.article_content h1{
    font-size: 26px;
    color: var(--plat-six);
}

div.blog_section_1 > article div.article_content h1 a:hover, 
div.blog_section_3 article div.article_content h1 a:hover{
    text-underline-position: under;
}

div.blog_section_1 > article div.article_content h1 a:hover{
    opacity: .95;
}

div.blog_section_3 article div.article_content h1 a:hover{
    text-decoration: underline 2px;
}

div.blog_section_3 article div.article_content p{
    font-size: 18px;
    margin-top: 20px;
    color: var(--plat-seven);
}

div.box_title_subcateg{
    border-bottom: 1px solid var(--plat-two);
    margin-bottom: 50px;
}

div.box_title_subcateg span.titsubcat{
    color:var(--plat-six);
    font-size: 18px;
    margin-bottom: 10px;
    display: block;
}

div.box_title_subcateg span.titsubcat a{
    color: var(--first-color);
    text-decoration: none;
}

div.box_title_subcateg span.titsubcat a:hover{
    text-decoration: underline;
}

div.box_sect_articles > p{
    color: var(--plat-seven);
    font-size: 18px;
}


/*Media Queries*/
@media (min-width: 541px) {
    div.blog_content div.blog_section_1{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
    } 

    div.blog_section_1 > article:hover{
        transform: translateY(-3px);
        opacity: .95;
    }

    div.blog_content_article div.article_content span.categbox{
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    div.blog_content_article div.article_content span.pub_date{
        margin-bottom: 0px;
    }
}

@media (min-width: 550px) {
    div.box_blog,
    div.box_blog_art{
        margin: 120px 0px 40px 0px;
    }

    div.box_blog.alert-active,
    div.box_blog_art.alert-active{
        margin: 165px 0px 40px 0px;
    }
}

@media (min-width: 650px) {
    div.box_blog{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }

    div.blog_section_3 article div.article_bg_img{
        height: 225px;
    }
}

@media (min-width: 750px) {
    div.blog_section_1 > article{
        width: 48.5%;
    } 
}

@media (min-width: 990px) {
    div.box_blog{
        padding-left: 30px;
        padding-right: 30px;
        flex-direction: row;
    }

    div.box_blog_art{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-left: 30px;
        padding-right: 30px;
        flex-direction: row;
    } 

    div.blog_section_1 > article{
        width: 100%;
    }

    div.box_sect_articles{
        width: calc(100% - 380px);
        margin-right: 50px;
    }

    div.blog_content div.blog_section_3{
        flex-direction: row;
    }

    div.box_home_content{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: nowrap;
    }

    div.home_inside{
        width: 100%;
    }

    div.blog_section_full{
        width: calc(100% - 50px)
    }

    div.blog_section_3 article{
        margin-bottom: 30px;
    }
}

@media (min-width: 1100px) {
    div.box_blog,
    div.box_blog_art{
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media (min-width: 1200px) {
    div.blog_section_1 > article{
        width: 48.5%;
    }
}

@media (min-width: 1400px) {
    div.blog_section_3 article{
        flex-direction: row;
        padding: 5px;
    }

    div.blog_section_3 article div.article_content{
        margin-left: 15px;
        margin-top: 0;
    }

    div.blog_section_3 article > a{
        width: 700px;
    }
}

@media (min-width: 1500px) {
    div.blog_section_3 article div.article_content h1 a{
        font-size: 22px;
    }

    div.blog_section_3 article div.article_bg_img{
        height: 240px;
    }

    div.blog_section_3 article > a{
        width: 800px;
    }
}

@media (min-width: 1700px) {
    div.blog_section_1 > article{
        width: 31.8%;
    }
}

@media (min-width: 2100px) {
    div.blog_section_1 > article{
        width: 23.5%;
    }
}