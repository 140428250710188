div.modal_header div.modtitbox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

div.modal_header div.modtitbox img{
    margin-right: 12px;
}

div.modal_content_middle{
    position: relative;
}

div.modal_content_middle form{
    display: flex;
    flex-direction: column;
}

div.modal_content_middle form input.pswpad{
    padding: 0px 55px 0px 15px;
}

div.modal_content_middle div.form-group{
    width: 100%;
    position: relative;
}

div.modal_content_middle div.form-group img,
div.admin_con_table div.admin_box_form img.icon_information{
    position: absolute;
    right: 15px;
    cursor: pointer;
    width: 22.66px;
}

div.modal_content_middle div.form-group img.tooleye{
    right: 50px;
}

div.modal_content_middle div.form-group img.icon_eye_open, 
div.modal_content_middle div.form-group img.icon_information
div.admin_con_table div.admin_box_form img.icon_information{
    height: 17px;
    bottom: 26px;
}

div.modal_content_middle div.form-group img.icon_information,
div.admin_con_table div.admin_box_form img.icon_information{
    width: 6.5px;
    height: 17px;
    position: unset;
}

div.modal_content_middle div.form-group img.icon_eye_close {
    height: 19px;
    bottom: 25px;
}

div.modal_content_middle div.form-group img:hover{
    opacity: .95;
}

div.box_forget{
    display: flex;
    justify-content: flex-end;
}

div.box_alter_plan{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    height: auto;
}

div.modal_content_middle span.forget,
div.box_myprofile span.forget,
div.crs_box_intro_lgn span.forget,
div.box_alter_plan span{
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 30px;
    text-align: right;
    cursor: pointer;
}

div.box_alter_plan span{
    margin-bottom: 0px;
    margin-top: 8px;
}

div.modal_content_middle span.forget.fgt_dark,
div.box_labor_all div.box_forget span.forget{
    color: var(--plat-seven);
}

div.modal_content_middle span.forget:hover,
div.box_myprofile span.forget:hover,
div.crs_box_intro_lgn span.forget:hover,
div.box_alter_plan span:hover{
    text-decoration: underline;
}

img.icon_forget{
    width: 17px;
}

img.icon_cam{
    width: 20px;
    height: 17.5px;
}

img.icon_email{
    width: 22px;
}

div.box_login p{
    text-align: center;
}

.crop-big-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
  
.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
}

.controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
}

.slider {
    padding: 22px 0px;
}


/*Media Queries*/
/* @media (min-width: 415px) {
}

@media (min-width: 541px) {

} */

/* @media (min-width: 650px) {
}

@media (min-width: 750px) {
}

@media (min-width: 850px) {
}

@media (min-width: 990px) {
}

@media (min-width: 1100px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1300px) {
}

@media (min-width: 1400px) {
}

@media (min-width: 1500px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1700px) {
}

@media (min-width: 1800px) {
}

@media (min-width: 1900px) {
} */