.loader_articles_b{ /*blogArticlesB*/
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
  
.loader_article_3{ /*blogArticle3*/
    width: 100%;
    margin-bottom: 15px;
}
  
.loader_article_4{ /*blogArticle4*/
    width: 100%;
    margin: 15px 0px 50px 0px;
}

.loader_title_b{ /*blogTitle*/
    width: 100%;
    height: 30px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.loader_description_c{ /*blogDescriptionA*/
    width: 65%;
    height: 20px;
    margin-top: 10px;
    border-radius: 8px;
}

.loader_description_d{ /*blogDescriptionB*/
    width: 45%;
    height: 20px;
    margin-top: 10px;
    border-radius: 8px;
}

.loader_mini_rectangle_cat{ /*blogMiniRectangle*/
    width: 100%;
    height: 200px;
    border-radius: 8px;
}
  
@media (min-width:1400px) {
    .loader_articles_b{
      flex-direction: row;
    }
  
    .loader_article_3{
      width: 380px;
      margin-bottom: 30px;
    }

    .loader_mini_rectangle_cat{
        width: 380px;
    }

    .loader_articles_b{
      flex-wrap: unset;
    }
    .loader_article_4{
      margin: 0px 0px 0px 15px;
    }
}
  
@media (min-width: 1500px) {
    .loader_article_3{
      width: 360px;
    }

    .loader_mini_rectangle_cat{
        width: 360px;
    }
}