.placeholder{
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.placeholder .line_loader{
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.pulse div{
  animation: pulse 1s infinite ease-in-out;
  -webkit-animation:pulse 1s infinite ease-in-out;
}

@keyframes pulse {
  0%{
    background-color: var(--plat-five);
  }
  50%{
    background-color: var(--plat-two);
  }
  100%{
    background-color: var(--plat-five);
  }
}

@-webkit-keyframes pulse {
  0%{
    background-color: var(--plat-five);
  }
  50%{
    background-color: var(--plat-two);
  }
  100%{
    background-color: var(--plat-five);
  }
}

.wave div{
    animation: wave 1s infinite linear forwards;
    -webkit-animation:wave 1s infinite linear forwards;
    background: #f6f7f8;
    background: linear-gradient(to right, var(--plat-five) 8%, #dddddd 18%, var(--plat-five) 33%);
    background-size: 800px 104px;
}

@keyframes wave{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@-webkit-keyframes wave{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}