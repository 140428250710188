.loader_sidebar_article{ /*blogSidebarArticle*/
    width: 100%;
    margin-bottom: 30px;
    height: 125px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.loader_side_rectangle{ /*blogSideRectangle*/
    width: 45%;
    height: 100px;
    background:#E8E8E8;
    border-radius: 8px;
}

span.loader_text_ret{ /*span.blogTextRet*/
    width: 50%;
    display: flex;
    flex-direction: column;
}

.loader_m_rectangle{ /*rectangle*/
    width: 500px;
    height:32px;
    margin-bottom:15px;
    border-radius: 8px;
}

@media (min-width: 650px) {
    .loader_sidebar_article{ 
        height: 175px;
    }
}

@media (min-width: 990px) {
    .loader_sidebar_article{ 
        height: 110px;
    }
}