footer.big{
    padding: 40px 15px 0px 15px;
    background: var(--plat-one);
    border-top: 1px solid var(--plat-eight);
}

footer.big a img.logo{
    cursor: pointer;
}

h3.foot{
    font-size: 18px;
    color: var(--plat-six);
    margin-bottom: 20px;
}

p.foot{
    margin-bottom: 30px;
    color: var(--plat-seven);
    font-size: 18px;
}

footer.big div.social_midia{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 80px;
}

div.social_midia div.midia{
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

div.social_midia div.midia:hover{
    opacity: .8;
}

footer.big ul{
    list-style-type: none;
}

footer.big ul li{
    display: block;
    margin-bottom: 10px;
}

footer.big ul li a{
    font-size: 18px;
    text-decoration: none;
    color: var(--plat-seven);
}

footer.big ul li a:hover{
    color: var(--first-color);
}

p.copyright{
    text-align:center;
    padding: 20px 0px;
    font-size: 15px;
    color: var(--plat-seven);
    display: block;
    margin-bottom: 0;
    border-top: 1px solid var(--plat-two);
}

/*Media Queries*/
@media (min-width: 990px) {
    footer.big{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-left: 30px;
        padding-right: 30px;
    }

    p.copyright{
        width: 100%;
        text-align: center;
    }
}

@media (min-width: 1100px) {
    footer.big{
        padding-left: 60px;
        padding-right: 60px;
    }
}