div.search_box{
    background: var(--plat-one);
    width: 100vw;
    z-index: 1000;
    animation: go-right .3s;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 0px 15px;
}

div.search-results{
    width: 100%;
    background: var(--plat-two);
    padding: 8px 0px 8px 0px;
    position: absolute;
    z-index: 1;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 4px 8px var(--shadow-color);
}

div.search-results ul{
    list-style-type: none;
    width: 100%;
}

div.search-results ul li a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    width: 100%;
    padding: 7.5px 15px;
    color: var(--plat-seven);
    font-size: 16px;
}

div.search-results ul li a:hover{
    background: var(--plat-four);
    cursor: pointer;
}

div.search_inside_area{
    width: 82%;
}

div.box_close_srch_bar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: var(--plat-six);
}

div.box_close_srch_bar:hover{
    color: var(--first-color);
}

div.box_close_srch_bar svg{
    color: inherit;
}

div.box_close_srch_bar span.close_search_bar{
    margin-left: 12px;
    display: none;
}

@media (min-width: 800px) {
    div.search_inside_area{
        width: 650px;
    }
}

@media (min-width: 900px) {
    div.box_close_srch_bar span.close_search_bar{
        display: block;
    }
}

@media (min-width: 990px) {
    div.search_box{
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 1100px) {
    div.search_box{
        padding-left: 60px;
        padding-right: 60px;
    }
}