div.courses_menu{
    width: 100%;
}

div.box_categorys{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

div.box_categorys div.crs_tit_subct{
    margin-top: 20px;
}

div.box_categorys h6.tit_cat_filter{
    font-size: 27px;
    margin: 10px 0px 0px 0px;
    color: var(--plat-six);
}

div.website_section div.box_categorys p.tit_cat_filter{
    margin-bottom: 0;
    color:var(--plat-seven);
}

div.filter_box_content{
    padding: 20px 0px;
    margin: 0px 15px;
    display: flex;
    flex-direction: column;
}

div.crs_courses_filters{
    margin-bottom: 30px;
}

div.crs_catgs_filters{
    position: fixed;
    left: 0;
    top: 0;
    background: var(--plat-eleven);
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    animation: go-back .3s;
}

div.crs_catgs_filters h5,
div.crs_courses_filters h5{
    font-size: 16px;
    color: var(--plat-six);
    margin-bottom: 10px;
}

div.crs_catgs_filters h5.color_ft_first{
    color: var(--first-color);
}

.filter_label{
    color: var(--plat-seven);
}

div.crs_courses_filters{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.courses_menu ul{
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

div.courses_menu ul li{
    margin:0px 10px 15px 0px; 
}

div.courses_menu ul li a{
    background: transparent;
    border:1.5px solid var(--first-color);
    font-size: 16px;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 20px 20px 20px 0px;
    color:var(--first-color);
    font-weight: 450;
    display: block;
}

div.courses_menu ul li.course_active a, 
div.courses_menu ul li a:hover{
    background: var(--first-color);
    color:var(--plat-six);
}

div.courses_menu ul li.subcourse_active a{
    background: var(--first-color);
    color:var(--plat-six);
}

div.course_infos div.characteristics ul{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0;
}

div.course_infos a{
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
}

div.course_infos a button{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center; 
    color: var(--plat-six);
    font-size: 16px;
    height: 36px;
    width: 88%;
    border: 0;
    border-radius: 20px 20px 20px 0px;
    margin-top: 25px;
}

div.course_infos a button span {
    padding-right: 15px;
}

div.course_infos a button:hover{
    opacity: .8;
} 

div.crs_qtty_results{
    display: none;
}

.button_filter{
    margin-top: 30px !important;
    display: flex !important;
}

div.crs_qtty_results_mobile{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}

div.crs_qtty_results_mobile,
div.crs_qtty_results{
    color: var(--plat-seven);
}

div.box_mobile_filters{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 112px;
}

.formControlSizeFilter{
    min-width: 200px !important;
}

div.filter_fields{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

div.box_categs_tit_ic{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (min-width: 500px) {
    /* div.box_plat_categorys, */
    div.box_categorys{
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
    }

    /* div.box_plat_categorys div.crs_tit_subct, */
    div.box_categorys div.crs_tit_subct{
        margin-top: 10px;
    }

    /* div.box_plat_categorys h6, */
    div.box_categorys h6.tit_cat_filter{
        margin:0 0 0 15px;
    }

    div.box_categs_tit_ic{
        /* justify-content: flex-start;
        align-items: flex-start; */
        flex-direction: row;
    }
}

@media (min-width: 900px) {
    div.crs_catgs_filters{
        width: 250px;
        display: block !important;
        position: relative;
        background: transparent;
        animation: unset;
        height: auto;
        flex-shrink: 0;
        z-index: unset;
    }

    div.crs_qtty_results{
        display: block;
    }

    div.box_mobile_filters,
    div.crs_qtty_results_mobile,
    .button_filter{
        display: none !important;
    }

    .formControlSizeFilter{
        min-width: 250px !important;
    }

    div.filter_box_content{
        padding: unset;
        margin: unset;
        display: block;
        flex-direction: unset;
    } 

    div.filter_fields{
        display: block;
        justify-content: unset;
        align-items: unset;
    }

    div.crs_catgs_filters h5.h5_spacement{
        margin-top: 30px;
    }
}
