div.card_display div.card_simulator_add{
    width: 100%;
    height: 200px;
    background: var(--plat-three);
    border-radius: 4px;
    border: 1px solid var(--plat-eight);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--plat-seven);
}

div.card_display div.card_simulator_add:hover{
    cursor: pointer;
    background: var(--plat-four);
}

/*Media Queries*/
@media (min-width: 750px) {
    div.card_display div.card_simulator_add{
        width: 400px;
    }
}