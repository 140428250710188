div.box_website{
    padding: 0px 15px;
    display: flex;
    justify-content: center;
}

div.website_section{
    padding-top: 65px;
    padding-bottom: 65px;
}

div.wb_sec_grid,
div.wb_sec_grid_center{
    width:100%;
    display:flex;
    justify-content: flex-start;
    align-items: center;
}

div.website_section h1,
div.website_section h2{
    font-weight: bold;
}

div.website_section h1{
    color: var(--plat-six);
    font-size: 26px;
    margin-bottom: 25px;
}

div.website_section h1.main_title_ws{
    width: 100%;
}

div.website_section h2{
    color: var(--plat-six);
    font-size: 22px;
    text-align: left;
    margin-bottom: 10px;
}

div.website_section p{
    margin-bottom: 35px;
    color: var(--plat-seven);
    font-size: 18px;
}

div.website_section p.alg_lft{
    text-align: left;
    margin-bottom: 25px;
}

div.wb_sec_3{
    border-top: 1px solid var(--plat-two);
    border-bottom: 1px solid var(--plat-two);
}

div.wb_sec_4,
div.wb_sec_6{
    border-bottom:1px solid var(--plat-two);
    margin-top: 105px;
}

div.wb_sec_4.alert-active,
div.wb_sec_6.alert-active{
    margin-top: 145px;
}

div.wb_sec_4{
    padding-top: 50px;
    padding-bottom: 50px;
}

div.wb_sec_5{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

div.wb_sec_grid_center{
    justify-content: center;
    text-align: center;
}

div.tabs_home{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 50px;
    flex-direction: column;
}

/* div.plat_sec_1{
    background: var(--plat-one);
    border-bottom:1.5px solid var(--plat-three);
    margin-top: 105px;
} */

/*Planos Ini*/
div.web_home_sec3{
    background-image: linear-gradient(
        96deg, 
        rgba(1, 254, 135, .7) 7.70%, 
        rgba(59, 174, 155, 0.7) 41.20%, 
        rgba(180, 209, 86, 0.7) 75%, 
        rgba(250, 175, 0, .7) 98.20%
    );
}

div.web_home_sec3 div.web_home_sec3_inside{
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 65px;
    padding-bottom: 65px;
    display: block;
    z-index: 9;
}

div.web_home_sec3 div.web_home_sec3_inside div.bg_img_sec3{
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
    opacity: .015;

    background-image: url('../../../assets/imgs/website/gradepng2.png');
    background-size: cover;
    background-position: center;
}

div.web_home_sec3_inside div.web_home_sec3_inside_content{
    display: block;
    position: relative;
    z-index: 999;
}

div.web_home_sec3 h1{
    color: var(--plat-six);
    font-size: 26px;
    /* color: var(--plat-two); */
    margin-bottom: 25px;
}

div.web_home_sec3 p{
    color: var(--plat-seven-transparency);
    /* color: var(--plat-two); */
    font-size: 18px;
    margin-bottom: 35px;
    /* color: var(--plat-seven); */
}
/*Planos Fim*/

@media (min-width: 550px) {
    div.wb_sec_4,
    div.wb_sec_6{
        margin-top: 60px;
    }
    
    div.wb_sec_4.alert-active,
    div.wb_sec_6.alert-active{
        margin-top: 100px;
    }

    /* div.plat_sec_1{
        margin-top: 60px;
    } */
}

@media (min-width: 650px) {
    div.website_section h1{
        margin-bottom: 30px;
    }
}

@media (min-width: 800px) {
    div.wb_sec_5{
        flex-direction: row;
    }

    div.website_section h1.main_title_ws{
        width: 550px;
        margin: 0 auto 25px auto;
    }
}

@media (min-width: 990px) {
    div.box_website{
        padding-left: 30px;
        padding-right: 30px;
    }

    div.website_section p.alg_lft{
        margin-bottom: 0px;
    }

    div.tabs_home{
        flex-direction: row;
    }
}

@media (min-width: 1100px) {
    div.box_website{
        padding-left: 60px;
        padding-right: 60px;
    }

    div.website_section h1,
    div.web_home_sec3 h1{
        font-size: 34px;
        margin-bottom: 35px;
    }

    div.website_section h1.main_title_ws{
        width: 700px;
        margin-bottom: 35px;
    }

    div.website_section p,
    div.web_home_sec3 p{
        font-size: 20px;
    }
}