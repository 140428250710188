button.btn_changeplan{
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    color: var(--first-color);
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
}

button.btn_changeplan:hover{
    text-decoration: none;
}