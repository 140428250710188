.loader_box_articles{ /*blogArticles*/
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.loader_article { /*blogArticle*/
    width: 100%;
    margin-bottom: 50px;
}

.loader_rectangle{ /*blogRectangle*/
    width: 100%;
    height: 200px;
    border-radius: 8px;
    margin-bottom: 12px;
}

.loader_title{ /*blogTitle*/
    width: 100%;
    height: 27.5px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.loader_description_a{ /*blogDescriptionA*/
    width: 65%;
    height: 15px;
    margin-top: 10px; /**/
    border-radius: 8px;
}

.loader_description_b{ /*blogDescriptionB*/
    width: 45%;
    height: 15px;
    margin-top: 10px;
    border-radius: 8px;
}

/*Media Queries*/
@media (min-width: 750px) {
    .loader_article{
      width: 48.5%;
    }
}
  
@media (min-width: 990px) {
    .loader_article{
      width: 100%;
    }
}

@media (min-width: 1200px) {
    .loader_article{
        width: 48.5%;
    }
}
  
@media (min-width: 1700px) {
    .loader_article{
        width: 31.8%;
    }
}
  
@media (min-width: 2100px) {
    .loader_article{
        width: 23.5%;
    }
}