.loader_box_home_laboratory{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding-left: 15px;
    padding-right: 15px;
    overflow-x: hidden;
}

.loader_course_single {
    width: 100%;
    margin-bottom: 30px;
    flex-shrink: 0;
    object-fit: cover;
}

.loader_course_single_tit{
    margin-bottom: 30px;
    width: 60%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 75px;
}

.loader_rectangle_crs_sgl{
    width: 100%;
    height: 36px;
    border-radius: 8px;
}

/*Media Queries*/
@media (min-width: 750px) {
    .loader_course_single {
        width: calc((100% - 20px - 2px) / 2);
    }

    .loader_course_single_tit{
        width: 350px;
    }
}

@media (min-width: 990px) {
    .loader_box_home_laboratory,
    .loader_course_single_tit{
        padding-left: 30px;
        padding-right: 30px;
    }

    .loader_course_single {
        width: calc((100% - 40px - 2px) / 3);
    }
}

@media (min-width: 1100px) {
    .loader_box_home_laboratory,
    .loader_course_single_tit{
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media (min-width: 1350px) {
    .loader_course_single {
        width: calc((100% - 60px - 2px) / 4);
    }
}

@media (min-width: 1700px) {
    .loader_course_single {
        width: calc((100% - 80px - 2px) / 5);
    }
}