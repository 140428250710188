div.form_loader{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
}

div.form_loader div{
    height: 56px;
    border-radius: 4px;
}

@media (min-width: 790px) {
    div.form_loader{
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
    }
}

@media (min-width: 990px) {
    div.form_loader{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (min-width: 1400px) {
    div.form_loader{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}