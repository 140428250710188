.loader_box_cat_subc_ct{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.loader_box_in_left{
  display: none;
  height: 505px;
  width: 200px;
  margin-right: 50px;
  border-radius: 8px;
}

.loader_box_in_right{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.loader_rectangle_crs_catsubc{
  width: 100%;
  height: 190px;
  border-radius: 8px;
}

.loader_course_catsubc{
  margin-bottom: 30px;
  border-radius: 8px;
  width: 100%;
}

@media (min-width: 900px) {
  .loader_box_in_left{
    display: block;
  }

  .loader_box_in_right{
    width: calc(100% - 250px);
  }
}
  
@media (min-width:1200px) {
  .loader_course_catsubc{
    width: 48.8%;
  }
}
  
@media (min-width: 1536px) {
  .loader_course_catsubc{
    width: 32%;
  }
}