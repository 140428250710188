div.crs_class_video_box,
div.crs_class_vid_header_box{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 60px;
    padding-bottom: 60px;
}

div.crs_class_vid_middle_box{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

div.crs_class_vid_header_box{
    width: 100%;
    background: var(--plat-three);
}

div.crs_class_vid_middle_box{
    width: 100%;
    background: var(--plat-four);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.cl_vid_header_top{
    margin-bottom: 40px;
}

div.cl_vid_header_top h1{
    font-size: 20px;
    color: var(--first-color);
}

div.cl_vid_header_top h2{
    font-size: 24px;
    color: var(--plat-six);
    margin-bottom: 20px;
}

div.cl_vid_header_top p{
    color: var(--plat-seven);
    font-size: 18px;
}

div.crs_cl_vid_header{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

a.download_link{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0px;
    cursor: pointer;
    color: var(--plat-six);
    text-decoration: none;
}

a.download_link p{
    color: var(--plat-six);
}

a.download_link:hover{
    text-decoration: underline;
}

div.circle_commts{
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: var(--plat-twelve);
    color: var(--plat-two);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12.5px;
}

div.load_more_topics,
div.load_more_topics_comms{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

div.load_more_topics{ 
    margin: 35px 0px;
}

div.load_more_topics_comms{
    padding: 35px 0px;
    background: var(--plat-three);
}

@media (min-width: 990px) {
    div.crs_class_video_box,
    div.crs_class_vid_header_box,
    div.crs_class_vid_middle_box{
        padding-left: 30px;
        padding-right: 30px;
    }

    div.cl_vid_header_top h1{
        font-size: 22px;
    }

    div.cl_vid_header_top h2{
        font-size: 28px;
    }
    
    div.cl_vid_header_top p{
        font-size: 20px;
    }
}

@media (min-width: 1100px){
    div.crs_class_video_box,
    div.crs_class_vid_header_box,
    div.crs_class_vid_middle_box{
        padding-left: 60px;
        padding-right: 60px;
    }
}