div.box_not_available{
    padding-left: 15px;
    padding-right: 15px;
    /* padding-top: 165px; */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.box_not_available.alert-active{
    padding-top: 210px;
}

div.box_not_available div.bna_content{
    width: 750px;
    height: auto;
    text-align: center;
}

div.bna_content h1{
    font-size: 25px;
    color: var(--plat-six);
    margin-bottom: 8px;
}

div.bna_content h1 br{
    display: none;
}

div.bna_content h2{
    font-size: 20px;
    color: var(--plat-twelve);
    margin-bottom: 5px;
}

div.bna_content p{
    font-size: 18px;
    color: var(--plat-seven);
    margin-bottom: 40px;
}

/*Media Queries*/
@media (min-width: 550px) {
    /* div.box_not_available{
        padding-top: 120px;
    } */

    div.box_not_available.alert-active{
        padding-top: 165px;
    }
}

@media (min-width: 650px) {
    div.bna_content h1 br{
        display: block;
    }
    
}

@media (min-width: 990px) {
    div.bna_content h1{
        font-size: 32px;
    }

    div.bna_content h2{
        font-size: 22px;
    }
    
    div.bna_content p{
        font-size: 20px;
    }
}

@media (min-width: 1100px) {
    div.box_not_available{
        padding-left: 60px;
        padding-right: 60px;
    }
}