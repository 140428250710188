/*Table | Colls| Cells */
div.admin_table_inside{
    width: 100%;
    padding-bottom: 50px;
    max-height: 100%;
}

div.admin_table_inside p{
    color: var(--plat-seven);
}

div.admin_table_inside table{
    width: inherit;
    border-spacing:0;
    border-collapse: collapse;
    text-align: left;
    margin-bottom: 50px;
    border: 1px solid var(--plat-eight);
}

div.admin_table_inside table thead tr{
    background: var(--plat-two);
}

div.admin_table_inside table thead tr th{
    padding: 15.5px 8px;
    font-weight: 600;
    color:var(--plat-six);
}

/* div.admin_table_inside table thead tr th:first-child{
    border-radius: 14px 0px 0px 0px;
}

div.admin_table_inside table thead tr th:last-child{
    border-radius: 0px 4px 0px 0px;
} */

div.admin_table_inside table tbody tr{
    border-spacing: 2px;
    border: 1px solid var(--plat-eight);
    cursor: pointer;
    background: var(--plat-four);
    color: var(--plat-seven);
}

div.admin_table_inside table tbody tr:hover{
    background-color: var(--plat-five);
}

div.admin_table_inside table tbody tr td{
    padding: 10px 8px;
    word-break:break-all;
}

div.admin_table_inside table tbody tr td img.icon-trash{
    width: 15px;
    height: 17px;
}

div.admin_table_inside table tbody tr td img.icon-trash:hover{
    opacity: .5;
}