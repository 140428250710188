
@font-face {
  font-family: AlbertSans;
  src: url('./assets/fonts/AlbertSans/AlbertSans-Regular.ttf');
  font-display: swap;
}

html{
  /* Os link internos/verticais/ancoras estavam ficando por baixo no navbar */
  scroll-padding-top: 180px; 
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'AlbertSans', 'Roboto', 'Segoe UI', 'Arial';
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

:root {
  /* Cores*/
  --plat-one: #111111;
  --plat-two: #1e1e1e;
  --plat-three: rgba(255, 255, 255, .02);
  --plat-four: rgba(255, 255, 255, .04);
  --plat-five: rgba(255, 255, 255, .06);
  --plat-six: rgba(255, 255, 255, .9);
  --plat-seven: rgba(255, 255, 255, .55);
  --plat-seven-transparency: rgba(255, 255, 255, .75);
  --plat-eight: #2A2A2A;
  --plat-nine: #989898;
  --plat-ten: rgba(255, 255, 255, .08);
  --plat-eleven: rgba(17, 17, 17, .98);
  --plat-twelve: #faaf00;
  --plat-gradient-color: linear-gradient(to bottom, var(--plat-one), var(--plat-two));
  
  --shadow-color:rgb(0 0 0 / 16%);
  --shadow-2-color: rgba(17, 17, 17, .85);
  --shadow-3-color: rgba(17, 17, 17, .75);
  --first-color: rgba(1, 254, 135, 1);
  --first-transparency: rgba(1, 254, 135, .45);
  --error-color:rgba(237, 67, 55, .9);
  --toast-error-color:rgba(237, 67, 55, .98);
  --toast-success-color:#4ba155;
  --toast-progress-color:rgba(255, 255, 255, .25);
  --toast-warning-color:#e0954b;
  --toast-warning-2-color:#E78326;
}

/* Change Autocomplete styles in Chrome */
  /*Modo escuro*/
    form.frm_dark input:-webkit-autofill,
    form.frm_dark input:-webkit-autofill:hover, 
    form.frm_dark input:-webkit-autofill:focus,
    form.frm_dark input:-webkit-autofill:active{
    /* form.frm_dark textarea:-webkit-autofill,
    form.frm_dark textarea:-webkit-autofill:hover,
    form.frm_dark textarea:-webkit-autofill:focus,
    form.frm_dark select:-webkit-autofill,
    form.frm_dark select:-webkit-autofill:hover,
    form.frm_dark select:-webkit-autofill:focus { */
      /* border: 1px solid var(--plat-seven); */
      -webkit-text-fill-color: var(--plat-six);
      -webkit-box-shadow: 0 0 0px 1000px var(--plat-two) inset; 
      transition: background-color 5000s ease-in-out 0s !important;
    }

    form.frm_dark input:-webkit-autofill:focus{
      /* border: 1px solid var(--plat-seven); */
      -webkit-box-shadow: 0 0 0px 1000px var(--plat-four) inset; 
    }

html {
  scroll-behavior: smooth;
}

body{
  background: var(--plat-gradient-color);
}

/*Início - Estilizar Barra de Rolagem*/
::-webkit-scrollbar {
  width: 10px; /*13PX*/
}

::-webkit-scrollbar-track {
  background: var(--plat-one);
}

::-webkit-scrollbar-thumb {
  background-color: var(--first-color);
  border-radius: 4px;
  border: 2px solid var(--plat-two);
} 
/*Fim - Estilizar Barra de Rolagem*/


/* No chrome quando deixamos a senha salva, existe uma falha que altera a estilização do input.
Isso ameniza o problema*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   -webkit-transition-delay: 9999s;
   transition-delay: 9999s;
}

/*Cor do icone do calendario nos inputs de data*/
::-webkit-calendar-picker-indicator {
  filter: brightness(0) saturate(100%) invert(56%) sepia(0%) saturate(1535%) hue-rotate(128deg) brightness(99%) contrast(94%);
}