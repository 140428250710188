div.box_contact,
div.box_profile{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 165px;
    padding-bottom: 155px;
}

div.box_contact.alert-active,
div.box_profile.alert-active{
    padding-top: 210px;
}

div.box_contact div.contact_content,
div.box_profile div.profile_content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

div.box_contact div.contact_content div.contact_inside,
div.box_profile div.profile_content div.contact_inside{
    width: 100%;
}

div.box_contact div.contact_content div.contact_inside{
    margin-right: 50px;
}

div.box_pg_contact.box_contact div.contact_content div.contact_inside{
    margin-right: 0;
}

div.box_contact h1,
div.box_profile h1{
    font-size: 27px;
    color: var(--plat-six);
    margin-bottom: 8px;
}

div.box_contact p.descp_sup_prof,
div.box_profile p.descp_sup_prof{
    font-size: 18px;
    color: var(--plat-seven);
    margin-bottom: 30px;
}

div.box_contact form,
div.box_profile form{
    width: 100%;
}

/*Media Queries*/
@media (min-width: 550px) {
    div.box_contact,
    div.box_profile{
        padding-top: 120px;
    }

    div.box_contact.alert-active,
    div.box_profile.alert-active{
        padding-top: 165px;
    }
}

@media (min-width: 650px) {
    div.box_contact,
    div.box_profile{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 200px;
    }

    div.box_contact div.contact_content,
    div.box_profile div.profile_content{
        flex-direction: row;
    }
}

@media (min-width: 990px) {
    div.box_contact h1,
    div.box_profile h1{
        font-size: 32px;
    }
    
    div.box_contact p.descp_sup_prof,
    div.box_profile p.descp_sup_prof{
        font-size: 20px;
    }
}

@media (min-width: 1100px) {
    div.box_contact,
    div.box_profile{
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 300px;
    }
}