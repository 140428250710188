h6{
    font-size: 18px;
    color: var(--first-color);
    font-weight: 600;
    margin-bottom: 8px;
}

h2{
    color: var(--plat-six);
    font-size: 24px;
    margin-bottom: 10px;
}

div.box_error{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 550px;
    width: 100%;
}

div.box_error h1.error404{
    font-size: 26px;
    color: var(--plat-six);
    text-align: center;
    margin-left: 10px;
}

div.box_error span.errorcode{
    font-size: 18px;
    color: var(--plat-seven);
}

div.box_error_tit{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}

@media (min-width: 990px) {
    div.box_error h1.error404{
        font-size: 32px;
    }

    div.box_error span.errorcode{
        font-size: 20px;
    }
}