div.course_content{
    padding: 40px 0px;
    width: 100%;
}

div.course_details{
    width: 100%;
}

div.course_header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

div.course_header div.course_top_head{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

div.course_top_head div.course_thumb{
    width: 60px;
    height: 60px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px var(--shadow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .96;
    flex-shrink: 0;
    margin-bottom: 15px;
}

div.course_top_head div.course_thumb svg{
    fill: var(--plat-six);
}

div.course_top_head div.course_title{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 15px;
}

div.course_title h1{
    font-size: 24px;
    color: var(--plat-six);
    margin-bottom: 1px;
}

div.course_description p{
    margin-bottom: 25px !important;
    font-size: 18px;
}

div.course_subtitle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

div.course_subtitle h2 {
    margin-bottom: 0;
    font-size: 22px;
}

/* div.course_list ul{
    margin-left: 35px;
    list-style-type: none;
}

div.course_list ul li{
    font-size: 18px;
    color: var(--plat-seven);
    margin: 10px 0px;
}
div.course_list ul li::before {
    content: "\2022"; 
    color: var(--first-color);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
} */

div.course_details div.course_dscp p{
    font-size: 18px;
    color: var(--plat-seven);
    margin-bottom: 20px;
}

div.course_section_one{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

/*div.course_section_one */div.course_box_produced{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
}

/*div.course_section_one*/ div.course_box_evaluation{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

div.course_box_evaluation span.course_eval,
div.course_section_one span.course_produced,
span.course_produced{
    color: var(--plat-seven);
}

div.course_section{
    margin: 50px 0px;
}

div.course_content aside {
    width: 100%;
}

div.information_course{
    width: 100%;
    margin-top: 75px;
}

div.information_course ul{
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

div.information_course ul li{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
    color:var(--plat-seven);
    font-size: 15px;
}

div.information_course ul li svg{
    margin-right: 10px;
}
 
div.information_course ul li:nth-child(5), 
div.information_course ul li:nth-child(6) {
    margin-bottom: 0px;
}

.box_video_class_one{
    width: 100%;
    position: relative;
    margin-bottom: 50px;
}

div.teacher_infos{
    margin-bottom: 50px;
}

div.teacher_infos div.teacher_title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

div.teacher_infos div.teacher_title h6{
    margin-bottom: 0;
    color: var(--plat-twelve);
}

div.teacher_details{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}

div.teacher_details div.teacher_box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
}

div.teacher_box img {
    width: 60px;
    height: 60px;
    box-shadow: 0px 0px 6px rgb(55 71 79 / 72%);
    border-radius: 100%;
    border: 2px solid var(--plat-twelve);
    margin-right: 12px;
}

div.teacher_box h4{
    margin-bottom: 0px;
    font-size: 18px;
    color:var(--plat-six);
}

div.teacher_details p,
div.teacher_infos p{
    font-size: 18px;
    color: var(--plat-seven);
    margin-bottom: 0;
}

div.course_title_right a{
    text-decoration: none;
}

a button.btn_course_access{
    display: none;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1.5px solid var(--first-color);
    font-size: 16px;
    padding: 10px 20px;
    color: var(--first-color);
    border-radius: 7px;
    font-weight: 450;
}

a button.btn_course_access:hover{
    background: var(--first-color);
    cursor: pointer;
    color:var(--plat-six);
}

div.course_title_free_or_not{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@media (min-width: 450px) {
    div.course_header div.course_thumb{
        width: 65px;
        height: 65px;
    }
}

@media (min-width: 541px) {
    div.course_section_one{
        /* justify-content: space-between; */
        align-items: flex-start;
        flex-direction: row;
    }

    /*div.course_section_one*/ div.course_box_produced{
        margin-bottom: 0px;
    }
}

@media (min-width: 600px) {
    div.information_course ul li{
        width: 33.33%;
        font-size: 16px;
    }

    div.information_course ul li:nth-child(4){
        margin-bottom: 0px;
    }
}

@media (min-width: 650px) {
}

@media (min-width: 850px) {
    div.course_content{
        display: flex;
        justify-content: space-between;
    }

    div.box_content{
        width: calc(100% - 370px);
    }

    div.box_content::after{
        display: block;
        clear: both;
        content: '';
    }

    div.course_section{
        margin: 50px 0px 0px 0px;
    }

    div.course_content aside{
        width: 320px;
    }
}

@media (min-width: 990px) {
    div.course_header{
        flex-direction: row;
    }

    div.information_course{
        width: 380px;
        margin-top: unset;
    }

    div.information_course ul li{
        width: 50%;
    }

    div.information_course ul li:nth-child(4){
        margin-bottom: 30px;
    }
}

/* @media (min-width: 1100px) {
} */

@media (min-width: 1200px) {
    div.course_title h1 {
        font-size: 26px;
    }

    a button.btn_course_access{
        display: flex;
    }
}

@media (min-width: 1300px) {
    div.box_content{
        width: calc(100% - 430px);
    }

    div.course_content aside{
        width: 380px;
    }
}

@media (min-width: 1400px) {
    div.course_title h1 {
        font-size: 32px;
    }
}

/* @media (min-width: 1500px) {
} */

/* @media (min-width: 1600px) {
} */

/* @media (min-width: 1700px) {
} */

/* @media (min-width: 1800px) {
} */

/* @media (min-width: 1900px) {
} */