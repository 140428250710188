div.admin_box_form{
    width: 100%;
}

div.box_checkbox{
    display: flex;
    background: var(--plat-two);
    margin-bottom: 10px;
    border-radius: 4px;
    border:1px solid var(--plat-eight);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 15px;
    border-left: 1px solid var(--plat-six);
}

div.box_checkbox span{
    color: var(--plat-seven);
}

div.gallery_publications{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 25px;
}

div.gallery_publications div.box_gallery_img{
    width: 300px;
    height: 220px;
    position: relative;
    border-radius: 4px;
    background: var(--plat-two);
    flex-grow: 1;
}

div.box_gallery_img:hover{
    opacity: .9;
}

div.box_gallery_img img{
    width: 100%;
    border-radius: 4px;
    height: 100%;
}

div.box_gallery_img div.box_inside_gall{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px 7px;
    background: var(--plat-eight);
    border-radius: 0px 0px 4px 4px;
}

div.box_gallery_img div.icons_gall_pub{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

div.box_gallery_img div.icons_gall_pub svg:hover{
    color: var(--plat-six);
}

div.gallery_publications div.box_gallery_img.send_image_pub{
    background: var(--plat-two) !important;
    color: var(--plat-seven) !important;
    border: 1px solid var(--plat-eight);
    display: flex;
    justify-content: center;
    align-items: center;
}

div.box_gallery_img div.icons_gall_pub svg,
div.gallery_publications div.box_gallery_img.send_image_pub:hover,
div.gallery_publications div.box_gallery_img.send_image_pub svg:hover{
    cursor: pointer;
}

div.list_smart{
    background: transparent;
    border: 1px solid var(--plat-eight);
    border-left: 1px solid var(--plat-six);
    padding: 20px 10px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
}

div.list_smart.list_add{
    border-left: 1px solid var(--plat-twelve);
}

div.list_smart.list_add div.list_icon_center{
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: var(--plat-five);
    border: 1px solid var(--plat-eight);
}

div.list_smart.list_add div.list_icon_center:hover{
    background: var(--plat-four);
}

span.list_icon_txt{
    margin-right: 10px;
    color: var(--plat-seven);
    font-size: 16px;
}

span.lit_dstq{
    color: var(--plat-twelve);
}

p.mdit{
    font-size: 16px;
    color: var(--plat-seven);
}

div.list_smart form{
    width: 100%;
    margin-bottom: 40px;
}

div.list_smart div.box_icons_smart{
    display: flex;
    justify-self: flex-end;
    align-self: flex-end;
}

div.box_icons_smart svg{
    margin: 0px 10px;
}

div.box_icons_smart svg:hover{
    opacity: .5;
    cursor: pointer;
}

@media (min-width: 600px) {
    div.list_smart{
        flex-direction: row;
    }

    div.list_smart form{
        margin-bottom: 0px;
        margin-right: 40px;
    }

    div.list_smart div.box_icons_smart{
        justify-self: unset;
        align-self: unset;
    }
}
