div.box_checkout_father div.box_checkout_default_card{
    background: var(--plat-two);
    box-shadow: 0px 3px 6px var(--shadow-color);
    border-top: 1px solid var(--first-color);
    border: 1px solid var(--plat-eight);
    width: 100%;
    border-radius: 4px;
    margin-top: 50px;
}

/* div.box_checkout_father div.box_checkout_default_card{
    padding: 25px 15px;
    margin-top: 50px;
} */

div.box_checkout_default_card div.deft_head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--plat-three);
    padding: 25px 15px;
    width: 100%;
    height: 67.5px;
    box-shadow: 0px 3px 6px var(--shadow-color);
    border-bottom: 1px solid var(--plat-three);
}

div.box_checkout_default_card div.deft_head h2{
    color: var(--first-color);
    font-size: 18px;
    margin-bottom: 0;
}

div.box_checkout_default_card div.deft_content{
    padding: 25px 15px;
}

div.deft_content div.card_populated{
    background: var(--plat-two);
    box-shadow: 0px 3px 6px var(--shadow-color);
    border: 1px solid var(--plat-eight);
    border-radius: 4px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 12px;
    opacity: .6;
}

div.deft_content div.card_populated:hover,
div.deft_content div.card_populated.card_selected:hover{
    background: var(--plat-four);
}

div.deft_content div.card_populated.card_selected{
    background: var(--plat-three);
    opacity: 1;
}

div.deft_content div.card_populated div.card_popu_left,
div.deft_content div.card_populated div.card_popu_right{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

div.deft_content div.card_populated div.card_popu_left span.card_default_descp{
    color: var(--plat-twelve);
    font-size: 14.5px;
    margin-bottom: 5px;
}

div.deft_content div.card_populated div.card_popu_left h4{
    color: var(--plat-six);
    font-size: 18px;
    font-weight: normal;
}

div.deft_content div.card_populated div.card_popu_left p{
    color: var(--plat-seven);
    font-size: 16px;
}

div.deft_content div.card_populated div.card_popu_right span{
    text-transform: uppercase;
    color: var(--first-color);
    font-size: 14.5px;
    margin-bottom: 20px;
}

div.deft_content div.card_populated div.card_popu_right span.card_change_click{
    color: var(--plat-seven);
}

div.deft_content div.card_populated div.card_popu_right div.card_venc{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

div.deft_content div.card_populated div.card_popu_right div.card_venc h5{
    font-size: 12px;
    color: var(--plat-seven);
}

div.deft_content div.card_populated div.card_popu_right div.card_venc span.dt_valid{
    color: var(--plat-seven);
    font-size: 16px;
    margin-bottom: 0;
}

div.deft_content div.box_add_new_card{
    display: flex;
    justify-content: center;
    margin: 15px 0px 45px 0px;
}

div.deft_content div.box_add_new_card button.card_pupulated_add_new_card{
    font-size: 14px;
    text-decoration: underline;
    color: var(--first-color);
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0;
}

div.deft_content div.box_add_new_card button.card_pupulated_add_new_card:hover{
    text-decoration: none;
}

div.deft_content div.box_add_new_card button.card_pupulated_add_new_card.card_add_blocked{
    opacity: .6;
}

div.deft_content div.box_add_new_card button.card_pupulated_add_new_card.card_add_blocked:hover{
    text-decoration: underline;
}

@media (min-width: 900px) {
    div.box_checkout_father div.box_checkout_default_card{
        width: 450px;
        margin-top: 0px;
    }
}

@media (min-width: 990px) {
    div.box_checkout_father div.box_checkout_default_card{
        width: 475px;
    }
}

@media (min-width: 1100px) {
    div.box_checkout_father div.box_checkout_default_card{
        width: 550px;
    }
}