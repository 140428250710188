div.modal_box{
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background: var(--plat-eleven);
    display: none;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    z-index: 9999;
}

div.modal_box.modal_open{
    display: flex;
    position: fixed;
}

div.modal_box div.modal,
div.modal_box div.modal_big{
    width: 100vw;
    height: 100vh;
    background: var(--plat-six);
    overflow: auto;
}

div.modal_box div.modal.mdl_blk,
div.modal_box div.modal_big.mdl_blk{
    background: var(--plat-eleven);
}

div.modal_box.modal_open div.modal{
    animation: go-back .3s;
}

div.filter_box_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    height: 100px;
}

div.filter_box_header{
    border-bottom: 1px solid var(--plat-two);
}

div.modal_header div.modtitbox h3,
div.filter_box_header h1 {
    font-size: 20px !important;
    margin-bottom: 0 !important;
}

div.modal_header div.modtitbox h3,
div.filter_box_header h1{
    color: var(--plat-six) !important;
}

div.modal_header div.modtitbox h3.mdl_blk{
    color: var(--plat-six) !important;
}

div.filter_box_header img.icon_close,
div.modal_header img.icon_close{
    cursor: pointer;
}

@keyframes go-back { /*Efeito abertura modal deslizando de baixo pra cima*/
    0% {
        transform: translateY(100vh);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes go-right { /*Efeito abertura modal deslizando da esquerda pra direita*/
    0% {
        transform: translateX(-100vw);
    }
    100% {
        transform: translateX(0);
    }
}

div.modal_content{
    width: 100%;
}

div.modal_content div.modal_header{
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
    margin-bottom: 50px;
    padding: 0px 15px;
    height: 100px;
}

div.modal_content div.modal_header.mdl_blk{
    border-bottom: 1px solid var(--plat-two);
}

div.modal_header button.cancel{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

div.modal_header button.cancel:hover{
    background: var(--first-color);
    color: var(--plat-six);
}

div.modal_content div.modal_content_middle{
    text-align: left;
    color:var(--plat-six);
    font-size: 16px;
    margin-bottom: 15px;
    padding: 0px 15px;
}

div.modal_content_middle > p{
    margin-bottom: 30px;
    font-size:18px;
    color: var(--plat-seven);
}

div.modal_content_middle > p.mdl_blk{
    color: var(--plat-seven);
}

div.modal_content div.modal_footer{
    display: flex;
    justify-content: flex-end;
    padding: 0px 15px;
}

/*Video Embed Ini*/
.video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 4px;
}
/*Video Embed Fim*/

div.modal_box div.modal.modal_payment{
    width: 100%;
}

/*Media Queries*/
@media (min-width: 650px) {
    div.modal_box{
        width: calc(100vw - 10px); /*10px = largura do scrollbar*/
    }

    div.modal_box div.modal,
    div.modal_box div.modal_big{
        width: 425px;
        height: auto;
        padding-bottom: 50px;
        border-radius: 8px;
    }

    div.modal_box div.modal_big{
        width: 550px;
    }

    div.modal_box div.modal.mdl_blk,
    div.modal_box div.modal_big.mdl_blk{
        border:1px solid #222;
    }

    div.modal_content div.modal_header,
    div.modal_content div.modal_content_middle,
    div.modal_content div.modal_footer{
        padding: 0px 35px;
    }

    div.modal_box div.modal.modal_video{
        width: 550px;
    }

    div.modal_box div.modal.modal_change_plan{
        width: 530px;
    }
}

@media (min-width: 800px) {
    div.modal_box div.modal.modal_payment{
        width: 540px;
    }
}

@media (min-width: 990px) {
    div.modal_box div.modal.modal_video{
        width: 790px;
    }

    div.modal_box div.modal.modal_change_plan{
        width: 590px;
    }

    div.modal_box div.modal_big{
        width: 800px;
    }
}

/* 
@media (min-width: 1100px) {
}

@media (min-width: 1300px) {
}

@media (min-width: 1400px) {
}

@media (min-width: 1500px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1700px) {
}

@media (min-width: 1800px) {
}

@media (min-width: 1900px) {
} */