div.step_lk_back{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7.5px 15px;
    cursor: pointer;
    width: 100%;
}

div.step_lk_back:hover{
    opacity: .9;
}

div.step_lk_back span.step_link{
    color: var(--first-color);
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

@media (min-width: 800px) {
    div.step_lk_back{
        width: 540px;
    }
}
