.loader_box_crs_all{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.loader_box_crs_in_left{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.loader_box_crs_in_right{
  display: none;
}

.loader_dscp_crs,
.loader_dscp_crs_b,
.loader_dscp_crs_c{
  height: 24px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.loader_dscp_crs{
  width: 100%;
}

.loader_dscp_crs_b{
  width: 80%;
}

.loader_dscp_crs_c{
  width: 60%;
}

@media (min-width: 850px) {
  .loader_box_crs_in_right{
    width: 320px;
    display: block;
    height: 555px;
    margin-left: 50px;
    border-radius: 8px;
  }

  .loader_box_crs_in_left{
    width: calc(100% - 370px);
  }
}
  
@media (min-width:1300px) {
  .loader_box_crs_in_right{
    width: 380px;
  }

  .loader_box_crs_in_left{
    width: calc(100% - 430px);
  }
}