div.box_blog div.box_home_content aside{
    margin-top: 40px;
}

aside div.box_merchan{
    width: 100%;
    background: var(--plat-four);
    border: 1px solid var(--plat-eight);
    box-shadow: 0px 3px 6px var(--shadow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

aside div.box_merchandise{
    background: var(--plat-four);
    max-width: 350px;
    height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--plat-seven);
}

aside div.little_article{
    width: 100%;
    height: 125px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
    transition: all .3s;
}

aside div.little_article div.lit_art_thumb{
    width: 180px;
    height: 100%;
}

aside div.little_article div.lit_art_title{
    width: 100%;
    height: 100%;
}

div.box_little_articles{
    margin-bottom: 40px;
}

div.lit_art_thumb a{
    width: 100%;
    height: 100%;
    display: block;
}

div.lit_art_thumb a div.lit_article_bg_img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    height: 125px;
    width: 160px;
    opacity: .85;
    box-shadow: 0px 3px 6px var(--shadow-color);
}

div.lit_art_thumb a:hover div.lit_article_bg_img{
    opacity: 1;
}

div.lit_art_title{
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

div.lit_art_title h2{
    font-size: 16px;
    margin-bottom: 0;
}

div.lit_art_title h2 a{
    color: var(--plat-six);
    text-decoration: none;
    font-size: 16px;
}

div.box_quiz_blog h2{
    color: var(--plat-six);
    margin-bottom: 20px;
    font-size: 18px;
}

div.box_quiz_blog div.quiz_opts{
    color: var(--plat-seven);
}

div.box_quiz_blog div.quiz_results{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--first-color);
    margin-top: 14px;
}

div.quiz_results a.res_quiz{
    color: var(--first-color);
    text-decoration: none;
    margin-right: 15px;
}

div.quiz_results a.res_quiz:hover{
    text-decoration: underline;
}

span.quiz-rsp-percent{
    font-weight: 600;
    font-size: 14px;
    color:var(--plat-twelve)
}

div.quiz_tit_ic{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

div.quiz_tit_ic > h1{
    font-size: 17px;
    margin: 0px 0px 0px 12px;
    color: var(--plat-twelve);
}

span.opt-quiz{
    font-size: 16px;
    padding: 5px 0px;
    display: block;
}

.css-ahj2mt-MuiTypography-root{
    font-family: 'AlbertSans', 'Roboto', 'Segoe UI', 'Arial' !important;
}

/*Media Queries*/
@media (min-width: 541px) {
    aside div.little_article:hover{
        transform: translateY(-3px);
    }

    aside div.little_article{
        height: 125px;
    }
}

@media (min-width: 650px) {
    aside div.little_article{
        height: 175px;
    }

    aside div.little_article div.lit_art_thumb{
        width: 380px;
    }

    div.lit_art_thumb a div.lit_article_bg_img{
        height: 175px;
        width: 380px;
    }

    div.lit_art_title{
        padding-left: 15px;
    }

    div.lit_art_title h2 a{
        font-size: 20px;
    }
}

@media (min-width: 990px) {
    div.box_blog aside,
    div.box_blog_art aside,
    div.box_contact aside,
    div.admin_content aside{
        width: 350px;
        flex-shrink: 0;
    }

    div.box_blog div.box_home_content aside{
        margin-top: 0px;
    }

    aside div.little_article{
        height: 110px;
    }

    aside div.little_article div.lit_art_thumb{
        width: 180px;
    }

    div.lit_art_thumb a div.lit_article_bg_img{
        height: 110px;
        width: 180px;
    }

    div.lit_art_title{
        padding-left: 10px;
    }

    div.lit_art_title h2 a{
        font-size: 16px;
    }
}
