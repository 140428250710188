div.payment_box_small_msg div.payment_select_plan{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
}

div.box_plan{
    width: 100%;
    border: 1px solid var(--plat-eight);
    height: auto;
    margin: 10px 0px;
    border-radius: 4px;
    background: var(--plat-three);
    box-shadow: 0px 3px 6px var(--shadow-color);
    border-top: 1px solid var(--first-color);
    cursor: pointer;
    transition: all .5s;
}

div.box_plan:hover{
    transform: scale(1.05);
}

div.box_plan div.a_plan_head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--plat-two);
    padding: 10px 20px;
    width: 100%;
    box-shadow: 0px 3px 6px var(--shadow-color);
    border-bottom: 1px solid var(--plat-eight);
}

div.a_plan_head span{
    color: var(--first-color);
    font-size: 14.5px;
}

div.box_plan div.b_plan_header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

div.box_plan div.b_plan_header span{
    color: var(--plat-seven);
    font-size: 14.5px;
}

div.box_plan div.b_plan_header h4{
    color: var(--first-color);
    font-size: 22px;
}

div.box_plan div.b_plan_middle{
    width: 100%;
    padding: 20px 20px 0px 20px;
    display: flex;
    justify-content: flex-start;
}

div.box_plan div.b_plan_middle h5{
    color: var(--plat-six);
    font-size: 22px;
}

div.box_plan div.b_plan_content{
    display: flex;
    justify-content: flex-start;
    padding: 20px 20px;
}

div.box_plan div.b_plan_content ul{
    list-style-type: none;
}

div.box_plan div.b_plan_content ul li{
    text-align: left;
    padding: 5px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

div.box_plan div.b_plan_content ul li span{
    color: var(--plat-seven);
    font-size: 16px;
    margin-left: 10px;
}

/* @media (min-width: 330px) {
}

@media (min-width: 800px) {
} */

@media (min-width: 900px) {
    div.payment_box_small_msg div.payment_select_plan{
        justify-content: space-around;
        flex-direction: row;
    }

    div.box_plan{
        margin: 0px 10px;
    }
}

/* @media (min-width: 990px) {
}

@media (min-width: 1100px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1500px) {
}

@media (min-width: 1800px) {
}

@media (min-width: 2000px) {
} */