.loader_box_single_laboratory{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding-left: 15px;
    padding-right: 15px;
    overflow-x: hidden;
    margin-bottom: 50px;
}

.loader_course_single_crs {
    width: 100%;
    margin-top: 100px;
    flex-shrink: 0;
    object-fit: cover;
}

.loader_infos_crs{
    width: 100%;
    height: 420px;
    border-radius: 4px;
}

/*Media Queries*/
@media (min-width: 990px) {
    .loader_box_single_laboratory{
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 1100px) {
    .loader_box_single_laboratory{
        padding-left: 60px;
        padding-right: 60px;
    }
}