/*Header*/
div.payment_header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: var(--plat-one);
    border-bottom: 1px solid var(--plat-eight);
    padding: 35px 0px;
    height: 104px;
}

div.payment_header a img{
    display: flex;
    justify-content: center;
    align-items: center;
}

div.payment_header img.logo{
    cursor: pointer;
}

div.website_pay_header_container{
    padding: 0px 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/*Fim Header*/

div.payment_box_big_msg{
    width: 100%;
    height: auto;
    min-height: calc( 100vh - 104px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, var(--plat-one), var(--plat-two));
    padding: 50px 0px;
}

div.payment_box_big_msg div.payment_box_message{
    width: 250px;
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.payment_box_message h1{
    color: var(--plat-six);
    font-size: 25px;
    text-align: center;
    width: 250px;
    margin: 20px 0px 10px 0px;
}

div.payment_box_message > p{
    color: var(--plat-seven);
    text-align: center;
    font-size: 18px;
    margin-bottom: 40px;
}

div.payment_box_small_msg{
    width: 100%;
    height: auto;
    min-height: calc( 100vh - 104px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(to bottom, var(--plat-one), var(--plat-two));
}

div.payment_box_small_msg div.payment_box_message{
    width: 100%;
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 15px;
}

div.payment_box_small_msg div.payment_box_message h1{
    width: 100%;
}

span.step_payment{
    color: var(--first-color);
    font-size: 13px;
    text-transform: uppercase;
}

/*Pagamentos Ini*/
div.website_payment_new{
    width: 100%;
    /* min-height: calc(100vh - 104px); */
    height: auto;
    padding: 0px 15px;
}

div.payment_content_new{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* flex-direction: column-reverse; */
}

div.payment_content_new h1{
    font-size: 17px;
    color: var(--plat-six);
}
/*Pagamentos Fim*/


@media (min-width: 330px) {
    div.payment_box_big_msg div.payment_box_message{
        width: 300px;
    }
}

@media (min-width: 990px) {
    /*Pagamentos Ini*/
    div.website_pay_header_container,
    div.website_payment_new{
        padding-left: 30px; 
        padding-right: 30px;
    }
    /*Pagamentos Fim*/
}

@media (min-width: 1100px) {
    /*Pagamentos Ini*/
    div.website_pay_header_container,
    div.website_payment_new{
        padding-left: 60px;
        padding-right: 60px;
    }
    /*Pagamentos Fim*/
}

@media (min-width: 1200px) {
    /*Pagamentos Ini*/
    div.payment_content_new h1{
        font-size: 18px;
    }
    /*Pagamentos Fim*/
}

@media (min-width: 1500px) {
    /*Pagamentos Ini*/
    div.payment_content_new h1{
        font-size: 19px;
    }

    div.website_pay_header_container,
    div.website_payment_new{
        padding-left: 90px;
        padding-right: 90px;
    }
    /*Pagamentos Fim*/
}

@media (min-width: 1800px) {
    /*Pagamentos Ini*/
    div.website_pay_header_container,
    div.website_payment_new{
        padding-left: 120px;
        padding-right: 120px;
    }
    /*Pagamentos Fim*/
}

@media (min-width: 2000px) {
    /*Pagamentos Ini*/
    div.website_pay_header_container,
    div.website_pay_container_new{
        width: 1760px;
        margin: 0 auto;
    }
    /*Pagamentos Fim*/
}