form.payment-form {
    width: 100%;
    align-self: center;
}

/*form.payment-form #payment-message-warning,*/
form.payment-form #payment-message{
    font-size: 14.5px;
    text-align: center;
    margin-top: 20px;
}

form.payment-form #payment-message {
    color: var(--error-color);
}

/* form.payment-form #payment-message-warning{
    color: var(--plat-twelve);
} */

form.payment-form #payment-element {
    height: 600px; 
}

@media (min-width: 284px) {
    form.payment-form #payment-element {
        height: 555px; 
    }
}

@media (min-width: 350px) {
    form.payment-form #payment-element {
        height: 500px; 
    }
}

@media (min-width: 480px) {
    form.payment-form #payment-element {
        height: 465px; 
    }
}

@media (min-width: 652px) {
    form.payment-form #payment-element {
        height: 370px; 
    }
}

@media (min-width: 800px) {
    form.payment-form #payment-element {
        height: 350px; 
    }
}

@media (min-width: 900px) {
    form.payment-form #payment-element {
        height: 470px; 
    }
}

@media (min-width: 990px) {
    form.payment-form #payment-element {
        height: 465px; 
    }
}