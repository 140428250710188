div.box_alert_important{
    background: var(--first-color);
    color: var(--plat-two);
    width: 100%;
    border-bottom: 1px solid var(--plat-two);
    height: 40px;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 998;
    left: 0;
    top: 0;
}

div.box_alert_important button{
    border: 0;
    background: transparent;
    color: var(--plat-two);
    cursor: pointer;
    font-weight: bold;
    transition: all .1s;
}

div.box_alert_important button:hover{
    transform: scale(1.2);
}

header.short{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    z-index: 998;
    padding-left: 15px;
    padding-right: 15px;
    background: var(--plat-one);
    position: fixed;
    top: 45px;
    border-bottom: 1px solid var(--plat-two);
}

header.short.head_labor{
    background: var(--plat-one);
}

header.short.alert-active{
    top: 85px;
}

div.content_top_area{
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header.short img.logo{
    cursor: pointer;
}

div.top_main_left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.hide_on_desktop,
.hide_on_desktop2,
.show_on_md_screen{
    display: block;
    margin-right: 10px;
    cursor: pointer;
}

.hide_on_desktop2{
    margin-right: 15px;
}

.logo_bar{
    display: block;
}

.hide_on_mobscr{
    display: none !important;
}

div.box_profile_lgn{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.box_profile_lgn img.notif{
    margin-right: 25px;
    width: 20px;
    cursor: pointer;
}

div.thumb_box, div.thumb_box_main{
    display: flex;
    justify-content: center;
    align-items: center;
}

div.thumb_box{
    margin-right: 12px;
    margin-left: -4px;
}

div.thumb_box_article{
    position: absolute;
    top: 10px;
    right: 10px;
}

div.thumb_box_article > img,
div.thumb_box_article > svg{
    width: 45px;
    height: 45px;
    box-shadow: 0px 3px 6px var(--shadow-color);
    border-radius: 50%;
    border: 2px solid var(--plat-six);
}

div.thumb_box_main img,
div.thumb_box img{
    height: 42px;
    width: 42px;
    border: 2px solid var(--plat-six);
    box-shadow: 0px 3px 6px var(--shadow-color);
    border-radius: 50%;
}

div.thumb_box img{
    border: 2px solid var(--plat-twelve);
}

div.user_description{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

div.user_description h6{
    margin-bottom: 0;
    color: var(--plat-twelve);
    font-size: 16px;
}

div.user_description span{
    margin-bottom: 0;
    font-size: 14.5px;
    color: var(--plat-seven);
}

.tooltiptext h6{
    color: var(--plat-six) !important;
    font-size: 16px;
}

.tooltiptext ul li{
    list-style-type: circle;
    font-size: 16px;
    margin-left: 20px;
    color: var(--plat-seven) !important;
}

div.dtls_of_notification{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:10px;
}

div.dtls_of_notification > p{
    text-align: right;
    font-size: 14px;
    color: var(--plat-twelve);
}

div.dtls_of_notification_block{
    display: block;
    margin-top: 10px;
}

div.dtls_of_notification_block > p{
    text-align: right;
}

div.box_notiftit{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
}

div.header_only_brand{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 15px;
    height: 45px;
    background: var(--plat-one);
    width: 100%;
    position: fixed;
    z-index: 998;
    left: 0;
    top: 0;
}

div.header_only_brand.logo_labor{
    background: var(--plat-one);
}

div.header_only_brand.alert-active{
    top: 40px;
}

div.header_only_brand img.logo{
    cursor: pointer;
}

nav.top_menu ul{
    display: flex;
    list-style-type: none;
    justify-content: flex-start;
    align-items: center;
}

nav.top_menu ul li{
    margin-right: 30px;
}

nav.top_menu ul li a{
    display: flex;
    justify-content: center;
    align-items: center;
    color:var(--plat-six);
    text-decoration: none;
    font-size: 16px;
}

nav.top_menu ul li:hover a{
    color:var(--first-color);
}

.css-19kzrtu {
    padding: 24px 5px !important;
}

.MuiInputBase-colorPrimary{ /*Largura do input de pesquisa do navbar*/
    width: 100% !important;
}

div.btns_on_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/*Media Queries*/
@media (min-width: 415px) {
    div.box_profile_lgn{
        position: relative;
    }

    .hide_on_desktop2{
        margin-right: 20px;
    }
}

@media (min-width: 550px) {
    .logo_bar{
        display: none !important;
    }

    .hide_on_mobscr{
        display: flex !important;
        justify-content: flex-start;
        align-items: flex-end;
    }
    
    header.short{
        top: 0px;
    }
    
    header.short.alert-active{
        top: 40px;
    }

    .hide_on_desktop2{
        margin-right: 30px;
    }
}

@media (min-width: 900px) {
    header.short img.logo{
        margin-right: 60px;
    }

    div.filter_box_header{
        display: none;
    }

    div.content_top_area{
        width: 100%;
        justify-content: flex-end;
    }
}

@media (min-width: 990px) {
    div.box_alert_important{
        padding-left: 30px;
        padding-right: 30px;
    }

    div.filter_box_header{
        display: none;
    }

    header.short{
        padding-left: 30px;
        padding-right: 30px;
    }

    .hide_on_desktop{
        display: none !important;
    }
}

@media (min-width: 1100px) {
    header.short, div.box_alert_important{
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media (min-width: 1280px) {
    .show_on_md_screen{
        display: none !important;
    }

    div.content_top_area{
        justify-content: space-between;
    }
}