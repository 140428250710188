div.box_myprofile div.profile_picture{
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    margin-bottom: 50px;
}

div.big_box_prof_pic{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

div.box_myprofile div.profile_picture div.box_profile_thumb{
    position: relative;
}

div.box_myprofile div.profile_picture div.box_profile_thumb > img{
    width: 215px;
    height: 215px;
    border-radius: 50%;
    border: 2px solid var(--plat-six);
    cursor: pointer;
}

div.modal_image div.modal{
    width: 100%;
}

div.modal_image div.modal_content div.modal_content_middle, div.crop-big-container{
    height: 300px;
}

div.box_myprofile div.profile_picture div.box_profile_thumb div.inp_image_box{
    width: 215px;
    height: 215px;
    border-radius: 50%;
    border: 2px solid var(--plat-six);
    cursor: pointer;
}

div.box_myprofile div.profile_picture div.box_profile_thumb div.inp_image_box img{
    width: 100%;
    height: 215px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px var(--shadow-color);
    border: 2px solid var(--plat-six);
}

div.box_myprofile div.profile_picture div.box_profile_thumb > img:hover{
    opacity: .95;
}

div.box_camera{
    width: 42px;
    height: 42px;
    background: var(--plat-two);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5px;
    bottom: 17.5px;
    cursor: pointer;
}

div.box_camera:hover{
    background: var(--plat-one);
}

div.box_camera > label{
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.box_camera > label > img{
    width: 20px;
    height: 17.5px;
    cursor: pointer;
}

span.inpdescpt{
    font-size: 16px;
    color: var(--plat-seven);
    margin-bottom: 5px;
    display: block;
}

div.myprofile_plan{
    width: 100%;
    background: var(--plat-three);
    border: 1px solid var(--plat-eight);
    box-shadow: 0px 3px 6px var(--shadow-color);
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 50px;
    height: auto;
    border-top: 1px solid var(--first-color);
}

div.myprofile_plan h2{
    font-size: 20px;
    margin-bottom: 30px;
    color: var(--plat-twelve);
}

div.myprofile_plan div.title_with_descp_plan_changed{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

div.myprofile_plan div.title_with_descp_plan_changed span{
    color: var(--toast-warning-color);
    margin-bottom: 35px;
}

div.box_mp_plan_change{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

div.mp_plan_infos h3{
    font-size: 18px;
    margin-bottom: 8px;
    color: var(--plat-six);
}

div.mp_plan_infos h3 span{
    margin-left: 5px;
}

div.mp_plan_infos h3 span.plan_activated{
    color: var(--first-color);
}

div.mp_plan_infos h3 span.plan_inactivated{
    color: var(--error-color);
}

div.mp_plan_infos > span{
    display: block;
    margin-bottom: 4px;
    font-size: 16.5px;
    color: var(--plat-seven);
}

div.mp_plan_infos{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

div.myprofile_plan div.mp_plan_infos > span.plan_msg_status_active{
    color: var(--toast-success-color);
    margin-bottom: 0;
}

div.myprofile_plan div.mp_plan_infos > span.plan_msg_status_canceled{
    color: var(--error-color);
    margin-bottom: 0;
}

div.mp_plan_actions{
    display: flex;
    align-items: flex-end;
}

div.myprofile_plan div.mp_plan_actions div.box_alter_plan span{
    color: var(--plat-seven);
}

div.box_mp_plan_main{
    width: 100%;
    border-bottom: 1px solid var(--plat-eight);
    margin-bottom: 40px;
    padding-bottom: 40px;
}

div.mprf_card_box{
    width: 100%;
}

div.mprf_card_box div.card_display{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    justify-content: flex-start;
    gap: 40px;
    width: 100%;
}

div.myprofile_plan p.card_description{
    color: var(--plat-seven);
    font-size: 16.5px;
    margin-bottom: 25px;
}

div.card_display span.rmv_card{
    display: flex;
    justify-content: center;
    color: var(--plat-seven);
    text-decoration: none;
    margin-top: 10px;
}

div.card_display span.rmv_card:hover{
    text-decoration: underline;
    cursor: pointer;
}

/*Media Queries*/
@media (min-width: 650px) {
    div.box_myprofile{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    div.box_myprofile div.profile_picture {
        margin-right: 50px;
        margin-bottom: 0;
    }

    div.modal_image div.modal{
        width: 550px;
    }
}

@media (min-width: 750px) {
    div.mprf_card_box div.card_display{
        grid-template-columns: auto;
    }
}

@media (min-width: 1300px) {
    div.mprf_card_box div.card_display{
        grid-template-columns: auto auto;
    }
}