div.crs_content_timeline{
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 50px;
}

div.crs_sgl_infos_all{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

div.crs_sgl_infos_new,
div.crs_sgl_quiz,
div.crs_sgl_infos_new_2,
div.crs_sgl_classes{
    background: var(--plat-three);
    border: 1px solid var(--plat-eight);
    border-radius: 4px;
    min-height: 300px;
    width: 100%;
    border-top: 1px solid var(--first-color);
    padding: 25px;
    margin-bottom: 30px;
}

div.crs_sgl_quiz,
div.crs_sgl_infos_new_2,
div.crs_sgl_classes{
    min-height: auto;
}

div.crs_sgl_infos_new h2,
div.crs_sgl_infos_new_2 h2{
    color: var(--first-color);
    font-size: 22px;
    margin-bottom: 30px;
    text-align: left;
}

div.crs_sgl_infos_new_2 p.intro_crs{
    color: var(--plat-seven);
    margin-bottom: 30px;
}

div.crs_sgl_infos_dscp{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

div.crs_sgl_infos_dscp p{
    font-size: 18px;
    color: var(--plat-seven);
    text-align: left;
}

div.crs_sgl_infos_dscp div.b_sgl_fdescp{
    margin-bottom: 30px;
}

div.crs_sgl_infos_dscp div.b_sgl_fdescp p{
    margin-bottom: 10px;
}

div.crs_sgl_infos_dscp div.b_sgl_more_infos{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

div.crs_sgl_infos_dscp img.pic{
    border-radius: 50%;
    width: 55px;
    height: 55px;
    box-shadow: 0px 3px 6px var(--shadow-color);
    margin-right: 15px;
    margin-bottom: 30px;
    border:1.5px solid var(--first-color);
}

div.crs_sgl_infos_dscp p.teacher_name{
    color: var(--plat-six);
}

/*Quiz Ini and Classes Ini*/
div.crs_sgl_quiz,
div.crs_sgl_classes{
    padding: 0px;
    margin-bottom: 0px;
}

div.sgl_quiz_header,
div.sgl_classes_header{
    background: var(--plat-four);
    display: flex;
    padding: 0px 25px;
    border-bottom: 1px solid var(--plat-eight);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 135px;
}

div.sgl_classes_header{
    border-bottom: none;
}

div.sgl_classes_header_back{
    background: var(--plat-three);
    height: 55px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 25px;
    border-bottom: 1px solid var(--plat-eight);
    cursor: pointer;
}

div.sgl_classes_header_back:hover{
    opacity: .9;
    background: var(--plat-one);
}

div.sgl_classes_header_back h2{
    color: var(--first-color);
    font-size: 18px;
    margin: 0 0 0 25px;
}

div.sgl_quiz_head_left,
div.sgl_classes_head_left,
div.certificate_title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}

div.certificate_title h2{
    margin: 0px 0px 0px 25px;
}

/* div.sgl_quiz_head_left,
div.sgl_classes_head_left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
} */

div.sgl_quiz_head_left div.sgl_quiz_title,
div.sgl_classes_head_left div.sgl_classes_title{
    margin-left: 15px;
}

div.sgl_quiz_head_left div.sgl_quiz_title h2,
div.sgl_classes_head_left div.sgl_classes_title h2{
    margin: 0;
    color: var(--first-color);
    font-size: 18px;
}

div.sgl_classes_head_left div.sgl_classes_title h2 span{
    font-size: 16px;
    margin-left: 10px;
    color: var(--plat-twelve);
}

div.sgl_quiz_head_left div.sgl_quiz_title span,
div.sgl_classes_head_left div.sgl_classes_title span.top_tic_main,
div.sgl_classes_head_right span.dt_pub{
    color: var(--plat-seven);
    font-size: 16px;
}

div.sgl_classes_head_left div.sgl_classes_title span{
    color: var(--plat-six);
    font-size: 18px;
}

div.sgl_quiz_head_right,
div.sgl_classes_head_right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

div.sgl_classes_head_right{
    align-self: flex-end;
}

div.sgl_quiz_head_right button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    margin-left: 5px;
    background: var(--plat-five);
    border: 1px solid var(--plat-two);
    border-radius: 4px;
    box-shadow: 0px 3px 6px var(--shadow-color);
}

div.sgl_quiz_head_right button:hover{
    background: var(--first-color);
    cursor: pointer;
}

div.sgl_quiz_head_right button.btn_disable{
    cursor: default;
    opacity: .3;
    pointer-events: none;
}

div.sgl_quiz_head_right button.btn_disable:hover{
    background: var(--plat-five);
}

div.sgl_classes_sub_header{
    /* height: 30px; */
    background: var(--plat-four);
    /* height: 80px; */
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    padding: 30px 25px 15px 25px;
    border-bottom: 1px solid var(--plat-eight);
}

div.sgl_classes_sub_header h1{
    font-size: 20px;
    color: var(--plat-six);
    margin-bottom: 5px;
}

div.sgl_classes_sub_header p{
    font-size: 18px;
    color: var(--plat-seven);
    margin-bottom: 15px;
}

div.sgl_topic_sub_header{
    background: var(--plat-two);
    padding: 75px 25px;
    border-bottom: 1px solid var(--plat-eight);
    word-break: break-all;
    overflow: auto;
}

div.sgl_topic_sub_header p{
    font-size: 18px;
    color: var(--plat-seven);
    margin-bottom: 0px;
}

div.sgl_quiz_content{
    padding: 35px 25px;
    background: var(--plat-three);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
}

div.sgl_classes_content{
    padding: 0px;
    background: var(--plat-three);
    width: 100%;
    /* display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column; */
}

div.sgl_quiz_cont_left{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 50px;
}

div.sgl_quiz_cont_left span{
    color: var(--first-color);
    font-size: 18px;
}

div.sgl_quiz_cont_left h1{
    color: var(--plat-six);
    font-size: 26px;
}

div.sgl_quiz_cont_left p{
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

div.sgl_quiz_cont_left p span{
    padding-left: 12px;
    color: var(--first-color);
}

div.sgl_quiz_cont_left p span.qst_error{
    color: var(--error-color);
}

div.sgl_quiz_cont_left div.qst_warn{
   display: flex;
   justify-content: flex-start;
   flex-direction: column;
   margin-top: 12px;
}

div.sgl_quiz_cont_left div.qst_warn span{
    color: var(--plat-nine);
}

div.sgl_quiz_cont_right{
    width: 100%;
}

div.sgl_quiz_cont_right button{
    width: 100%;
    height: 55px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px var(--shadow-color);
    text-transform: none;
    font-size: 18px;
}

div.sgl_quiz_cont_right button.question{
    margin-bottom: 20px;
    background: var(--plat-four);
    border: 1px solid var(--plat-eight);
    color: var(--plat-six);
    font-size: 16px;
    cursor: pointer;
}

div.sgl_quiz_cont_right button.question:hover{
    background: var(--plat-five);
    color: var(--first-color);
}

div.sgl_quiz_cont_right button.question.qst_checked{
    background: var(--plat-ten);
    color: var(--first-color);
    border-color: var(--first-color);
}

div.sgl_quiz_cont_right button.question.qst_checked.qst_correct{
    background: var(--first-transparency);
    color: var(--plat-six);
}

div.sgl_quiz_cont_right button.question.qst_checked.qst_incorrect{
    background: var(--error-color);
    color: var(--plat-six);
    border-color: var(--toast-error-color);
}

div.result_feedback{
    margin-top: 30px;
}
/*Quiz Fim*/

/*Tabela Ini*/
div.plt_class_alert{
    padding: 15px 25px;
}

div.plt_class_alert p{
    color: var(--plat-seven);
}

div.box_tbl_plat{
    width: 100%;
    /* height: 500px; */
    overflow: auto; 
}

table.tbl_plat_classes{
    border: 0;
    width: 100%;
    border-spacing: 0;
    white-space: nowrap;
    border-collapse: collapse;
}

table.tbl_plat_classes thead{
    background: var(--plat-eight);
    color: var(--plat-six);
}

table.tbl_plat_classes thead tr{
    padding: 0px 25px;
}

table.tbl_plat_classes thead tr th{
    text-align: left;
    height: 60px;
    line-height: 60px;
}

table.tbl_plat_classes thead tr th:nth-child(1),
table.tbl_plat_classes tbody tr td:nth-child(1) {
    padding-left: 25px;
}

table.tbl_plat_classes thead tr th,
table.tbl_plat_classes tbody tr td{
    padding-right: 25px;
}

table.tbl_plat_classes tbody{
    color: var(--plat-seven);
}

table.tbl_plat_classes tbody tr{
    border: 1px solid var(--plat-eight);
}

/* table.tbl_plat_classes tbody tr:nth-child(even){
    background: var(--plat-four)
} */

table.tbl_plat_classes tbody tr td{
    height: 50px;
    line-height: 50px;
}

table.tbl_plat_classes tbody tr:hover{
    cursor: pointer;
    background: var(--plat-five);
}

table.tbl_plat_classes tbody tr td div.box_circle_img{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

table.tbl_plat_classes tbody tr td img.circle_author,
div.sgl_classes_head_right img.circle_author{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid var(--plat-twelve);
    box-shadow: 0px 3px 6px var(--shadow-color);
}

div.sgl_classes_head_right img.circle_author{
    margin-left: 24px;
}

table.tbl_plat_classes tbody tr td.alignment{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
/*Tabela Fim*/


div.sgl_classes_head_right span.dt_pub {
    margin-left: 12px;
    color: var(--plat-twelve);
}

div.sgl_classes_head_right div.topic_post_date{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


div.crs_sgl_evaluation{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 5px;
}

div.crs_sgl_evaluation div.crs_sgl_eval{
    margin-bottom: 30px;
}

div.sgl_comment_header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 55px;
    background: var(--plat-three);
    padding: 0px 25px;
    border-bottom: 1px solid var(--plat-eight);
}

div.sgl_comment_header p{
    color: var(--plat-twelve);
    font-size: 16px;
    margin-left: 12px;
}

div.sgl_comment_sender,
div.sgl_comments_box div.sgl_comm_box{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 15px 25px;
    height: auto;
    width: 100%;
    background: var(--plat-three);
}

div.sgl_comment_sender_block{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 25px;
    height: auto;
    width: 100%;
    background: var(--plat-three);
}

div.sgl_comment_sender_block div.comm_text_block{
    margin-left: 25px;
}

div.comm_text_block h5{
    font-size: 18px;
    color: var(--plat-six);
}

div.comm_text_block p{
    color: var(--plat-seven);
    font-size: 16px;
}

div.sgl_comments_box div.sgl_comm_box:hover{
    background: var(--plat-four);
}

div.sgl_comment_sender div.comm_left{
    width: 40px;
    height: auto;
    margin-right: 12px;
}

div.sgl_comment_sender div.comm_left img.circle_author_2{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid var(--plat-six);
    box-shadow: 0px 3px 6px var(--shadow-color);
}

div.sgl_comment_sender div.comm_right{
    width: calc(100% - 67px);
}

div.comm_right h5{
    color: var(--plat-six);
    font-size: 16px;
    margin-bottom: 5px;
}

div.sgl_comment_sender div.comm_bottom{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

div.sgl_comments_box{
    display: flex;
    flex-direction: column;
}

/* div.sgl_comments_box div.sgl_comm_box{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 15px 25px;
    height: auto;
    width: 100%;
    background: var(--plat-three);
} */

div.sgl_comments_box div.sgl_comm_box div.comm_box_left {
    width: 40px;
    height: auto;
    margin-right: 12px;
}

div.sgl_comments_box div.sgl_comm_box div.comm_box_left img.circle_author_3{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid var(--plat-six);
    box-shadow: 0px 3px 6px var(--shadow-color);
}


div.sgl_comments_box div.sgl_comm_box div.comm_box_all_right{
    width: calc(100% - 104px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

div.sgl_comments_box div.sgl_comm_box div.comm_box_all_right div.comm_box_right{
    width: calc(100% - 104px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    
}

div.sgl_comments_box div.sgl_comm_box div.comm_box_all_right div.comm_full_box_right{
    width: 100%;
    margin-top: 7.5px;
}

div.sgl_comments_box div.sgl_comm_box div.comm_box_all_right div.comm_full_bottom{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

div.comm_box_right h5{
    color: var(--plat-six);
    font-size: 16px;
    margin-bottom: 0;
}

div.comm_box_right span.comm_dt_pretty{
    font-size: 14px;
    color: var(--plat-twelve);
}

div.comm_box_right p{
    font-size: 18px;
    color: var(--plat-seven);
    margin-bottom: 0px;
}

div.sgl_comments_box div.sgl_comm_box div.comm_box_right_final{
    width: 40px;
    height: auto;
    margin-left: 12px;
}



@media (min-width: 990px) {
    div.crs_content_timeline{
        padding-left: 30px;
        padding-right: 30px;
    }

    div.crs_sgl_infos_all{
        justify-content: space-between;
        align-items: stretch;
        flex-direction: row;
    }

    div.crs_sgl_infos_new{
        width: 48.5%;
        margin-bottom: 0px;
    }

    div.sgl_quiz_head_left div.sgl_quiz_title,
    div.sgl_classes_head_left div.sgl_classes_title{
        margin-left: 25px;
    }

    div.sgl_quiz_head_left div.sgl_quiz_title h2,
    div.sgl_classes_head_left div.sgl_classes_title h2{
        font-size: 22px;
    }

    div.sgl_quiz_head_left div.sgl_quiz_title span,
    div.sgl_classes_head_left div.sgl_classes_title span.top_tic_main,
    div.sgl_classes_head_right span.dt_pub{
        font-size: 18px;
    }

    div.sgl_classes_head_right{
        align-self: unset;
    }

    div.sgl_quiz_content{
        flex-direction: row;
    }

    div.sgl_quiz_cont_left{
        width: 40%;
        margin-bottom: 0px;
    }

    div.sgl_quiz_cont_right{
        width: 55%;
    }

    div.sgl_quiz_cont_left h1{
        font-size: 32px;
    }

    div.result_feedback{
        margin-top: 0px;
    }

    div.sgl_quiz_header,
    div.sgl_classes_header{
        height: 80px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    div.sgl_quiz_head_left,
    div.sgl_classes_head_left{
        margin-bottom: 0px; 
    }

    div.sgl_comments_box div.sgl_comm_box div.comm_box_all_right div.comm_box_right{
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
    }

    div.comm_box_right span.comm_dt_pretty{
        font-size: 14.5px;
        margin-left: 12px;
    }
}

@media (min-width: 1100px){
    div.crs_content_timeline{
        padding-left: 60px;
        padding-right: 60px;
    }
}