span.showErrorMessage{
    color: var(--error-color);
    font-size: 12.5px;
    margin-bottom: 4px;
    display: block;
}

div.inp_image_box{
    width: 100%;
    height: 225px;
    background: var(--plat-two);
    border: 1px solid var(--plat-eight);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

div.inp_image_box img{
    width: 100%;
    height: 225px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid var(--plat-eight);
}

.hide_on_mobile{
    display: none !important;
}


/*Media Queries*/
/* @media (min-width: 541px) {
} */

/* @media (min-width: 650px) {
} */

/* @media (min-width: 850px) {
} */

@media (min-width: 990px) {
    .hide_on_mobile{
        display: flex !important;
    }
}

@media (min-width: 1300px) {
    /* div.box_blog_art div.blog_content_article{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
    } */

    div.blog_content_article h1{
        font-size: 30px;
    }
}

/* @media (min-width: 1400px) {
}

@media (min-width: 1500px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1700px) {
}

@media (min-width: 1800px) {
}

@media (min-width: 1900px) {
} */