.beating img{
   animation: beating .5s infinite ease-in-out;
   -webkit-animation:beating .5s infinite ease-in-out;
 }

.beating img.logo_main {
   margin:0;
}
 
 @keyframes beating {
   0% {
      transform: scale(1);
   }
 
   50% {
      transform: scale(1.5);
   }
 
   100% {
      transform: scale(1);
   }
 }
 
 @-webkit-keyframes beating {
   0% {
      transform: scale(1);
   }
 
   50% {
      transform: scale(1.5);
   }
 
   100% {
      transform: scale(1);
   }
 }