.loader_box_home_banner_labor{
    width: 100%;
    height: 525px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 15px;
    padding-right: 15px; */
}

/*Media Queries*/
@media (min-width: 990px) {
    .loader_box_home_banner_labor{
        /* padding-left: 30px;
        padding-right: 30px; */
        height: 675px;
    }
}

/* @media (min-width: 1100px) {
    .loader_box_home_banner_labor{
        padding-left: 60px;
        padding-right: 60px;
    }
} */