div.box_construction{
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

div.construction_alert{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

div.construction_alert img{
    width: 138px;
    margin-bottom: 40px;
    cursor: pointer;
}

div.construction_alert hr{
    border: 1px solid var(--plat-nine);
    opacity: .02;
    margin-bottom: 40px;
}

div.construction_alert h1{
    font-size: 30px;
    font-weight: bold;
    color: var(--plat-six);
    margin-bottom: 15px;
}

div.construction_alert h1 span.alert_important{
    color: var(--plat-twelve);
}

div.construction_alert p{
    font-size: 18px;
    color: var(--plat-nine);
    margin-bottom: 45px;
}

@media (min-width: 990px) {
    div.box_construction{
        padding-left: 30px;
        padding-right: 30px;
    }

    div.construction_alert{
        width: 600px;
        height: 500px;
    }

    div.construction_alert img{
        width: 188px;
        margin-bottom: 60px;
    }

    div.construction_alert hr{
        margin-bottom: 60px;
    }

    div.construction_alert h1{
        font-size: 40px;
    }

    div.construction_alert p{
        font-size: 20px;
    }
}

@media (min-width: 1100px) {
    div.box_construction{
        padding-left: 60px;
        padding-right: 60px;
    }
}