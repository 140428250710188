div.box_labor_all{
    background-image: var(--plat-gradient-color);
}

div.crs_vid_content_all{
    background-image: var(--plat-gradient-color);
    padding-bottom: 80px;
}

div.course_videos{
    width: 100%;
    background: var(--plat-one);
    margin-top: 105px;
    height: auto;
}

div.course_videos div.labor_main_banner{
    width: 100%;
    height: 525px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

div.course_videos div.labor_main_banner,
div.row_course_list{
    padding-left: 15px;
    padding-right: 15px;
}

div.row_course_list.nullpad{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

div.labor_main_banner > span{
    display: block;
    color: var(--first-color);
    font-size: 18px;
}

div.labor_main_banner h1{
    color: var(--plat-six);
    font-size: 38px;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
}

div.labor_main_banner div.banner_box_dtls{
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 15px;
}

div.labor_main_banner div.banner_box_dtls div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

div.labor_main_banner div.banner_box_dtls span.banner_dtls{
    color: var(--plat-twelve);
    margin-left: 5px;
    font-size: 16px;
}

div.labor_main_banner p{
    width: 100%;
    color: var(--plat-seven);
    opacity: .95;
    font-size: 20px;
    margin-bottom: 40px;
    text-align: left;
}

span.labor_btn{
    color: var(--plat-two) !important;
}

p.crs_concluded{
    color: var(--first-color) !important;
}

div.row_course_list{
    padding-top: 50px;
    width: 100%;
    position: relative;
}

div.row_course_list h2{
    color: var(--plat-six);
    font-size: 24px;
    margin-bottom: 30px;
}

div.row_course_list div.row_group{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

div.row_group.dragging{
    cursor: grab;
    scroll-behavior: auto;
}

div.row_group.dragging div.crs_g_item{
    pointer-events: none;
}

div.row_group div.row_arrow_left,
div.row_group div.row_arrow_right{
    position: absolute;
    cursor: pointer;
    height: 201px;
    width: 60px;
    display: none;
    align-items: center;
    z-index: 99;
}

div.row_group div.row_arrow_right{
    display: flex;
}

div.row_group div.row_arrow_left:hover,
div.row_group div.row_arrow_right:hover{
    width: 80px;
}

/* div.row_group div.row_arrow_left svg,
div.row_group div.row_arrow_right svg{
    opacity: .95;
} */

div.row_group div.row_arrow_left{
    justify-content: flex-start;
    background: linear-gradient(to right, rgba(0, 0, 0, .45) 50%, transparent);
    padding-left: 7.5px;
    /* left: 0; */
    left: 15px;
}

div.row_group div.row_arrow_right{
    justify-content: flex-end;
    background: linear-gradient(to left, rgba(0, 0, 0, .45) 50%, transparent);
    padding-right: 7.5px;
    /* right: 0; */
    right: 15px;
}

div.row_group div.crs_g_item{
    border-radius: 8px;
    border:1px solid transparent;
    width: 100%;
    flex-shrink: 0;
    cursor: pointer;
    object-fit: cover;
}

div.row_group div.crs_g_item:hover{
    background: var(--plat-two);
    box-shadow: 0 3px 6px var(--shadow-color);
    border:1px solid var(--plat-eight);
}

div.crs_g_item div.item_thumb_outside{
    padding: 15px 10px 15px 10px;
    border-radius: 0px 0px 8px 8px;
    cursor: pointer;
    border: 1px solid transparent;
}

div.crs_g_item:hover div.item_thumb_outside{
    border-color: var(--plat-two);
}

div.crs_g_item div.item_thumb_outside > span{
    color: var(--plat-seven);
    display: block;
    margin-bottom: 10px;
    position: relative;
}

div.row_group div.crs_g_item div.item_thumb_inside{
    height: 200px;
    border-radius: 8px;
    position: relative;
}

div.row_group div.crs_g_item div.item_thumb_inside img{
    height: inherit;
    box-shadow: 0 3px 6px var(--shadow-color);
    border-radius: 8px;
    width: 100%;
}

div.row_group div.crs_g_item div.item_thumb_inside img:hover{
    cursor: pointer;
}

div.row_group div.crs_g_item h3{
    font-size: 18px;
    color: var(--plat-six);
}

/*Media Queries*/
@media (min-width: 550px) {
    div.course_videos{
        margin-top: 60px;
    }

    div.labor_main_banner div.banner_box_dtls{
        display: flex;
        flex-direction: column;
        flex-direction: row;
        column-gap: unset;
        row-gap: unset;
    }

    div.labor_main_banner div.banner_box_dtls div{
        justify-content: space-between;
        margin-right: 20px;
    }
    
    div.labor_main_banner div.banner_box_dtls div:nth-child(3){
        margin-right: 0px;
    }

    div.stack_margin{
        margin-left: 80px;
    }

    div.labor_main_banner div.banner_box_dtls span.banner_dtls{
        font-size: 18px;
    }
}

@media (min-width: 750px) {
    div.row_group div.crs_g_item{
        width: calc((100% - 20px - 2px) / 2);
    }

    div.row_group div.row_arrow_left,
    div.row_group div.row_arrow_right{
        align-items: center;
    }

    div.row_group div.row_arrow_right{
        display: flex;
    }
}

@media (min-width: 990px) {
    div.course_videos div.labor_main_banner{
        height: 675px;
        justify-content: flex-end;
        align-items: flex-start;
    }

    div.course_videos div.labor_main_banner,
    div.row_course_list{
        padding-left: 30px;
        padding-right: 30px;
    }

    div.labor_main_banner h1{
        font-size: 42px;
        width: 550px;
    }

    div.labor_main_banner p{
        width: 425px;
    }   

    .lbtn_labor{
        margin-bottom:90px !important;
    }

    .lbtn_labor:hover{
        transform: scale(1.1);
    }

    div.row_group div.crs_g_item{
        width: calc((100% - 40px - 2px) / 3);
    }

    div.row_group div.row_arrow_left{
        left: 30px;
    }
    
    div.row_group div.row_arrow_right{
        right: 30px;
    }
}

@media (min-width: 1100px){
    div.course_videos div.labor_main_banner,
    div.row_course_list{
        padding-left: 60px;
        padding-right: 60px;
    }

    div.row_group div.row_arrow_left{
        left: 60px;
    }
    
    div.row_group div.row_arrow_right{
        right: 60px;
    }
}

@media (min-width: 1350px){
    div.row_group div.crs_g_item{
        width: calc((100% - 60px - 2px) / 4);
    }
}

@media (min-width: 1700px){
    div.row_group div.crs_g_item{
        /* 80px = gap e 2px é da borda */
        width: calc((100% - 80px - 2px) / 5); 
    }
}

@media (min-width: 2200px){
    div.row_group div.crs_g_item{
        width: calc((100% - 100px - 2px) / 6);
    }
}