div.modal_crs_container{
    background: #111;
    width: inherit;
    height: inherit;
    border-radius: 8px;
    border:1px solid #1d1d1d;
    cursor: pointer;
}

div.modal_crs_container div.mdl_crs_thumb{
    height: 200px;
    position: relative;
}

div.modal_crs_container div.mdl_crs_thumb img{
    height: inherit;
    box-shadow: 0 3px 6px #111;
    border-radius: 8px 8px 0px 0px;
    width: 100%;
}

div.modal_crs_container div.mdl_crs_infos{
    padding: 15px 10px 15px 10px;
    cursor: pointer;
    border: 1px solid transparent;
}

div.mdl_crs_infos div.mdl_crs_title_header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

div.mdl_crs_infos div.mdl_crs_title_header > div > h3{
    font-size: 18px;
    color: var(--plat-six);
}

div.mdl_crs_infos div.mdl_crs_title_header > div > span{
    color: var(--plat-seven);
    display: block;
    margin-bottom: 10px;
}

div.modal_crs_container div.mdl_crs_infos div.mdl_crs_more_infos{
    position: absolute;
    bottom:0px;
    left: 0px;
    width: 100%;
}

div.modal_crs_container div.mdl_crs_infos div.mdl_crs_more_infos div.mdl_crs_m_i{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 10px 15px 10px;
}

div.modal_crs_container div.mdl_crs_infos div.mdl_crs_more_infos div.mdl_crs_m_i span{
    color: var(--first-color);
    display: block;
}

div.modal_crs_container div.mdl_crs_infos div.mdl_crs_more_infos div.mdl_crs_m_i div.mdl_crs_more_dtls{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

div.modal_crs_container div.mdl_crs_infos div.mdl_crs_more_infos div.mdl_crs_m_i div.mdl_crs_more_dtls div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
}

div.modal_crs_container div.mdl_crs_infos div.mdl_crs_more_infos div.mdl_crs_m_i div.mdl_crs_more_dtls div:nth-child(2){
    margin-right: 0px;
}

div.modal_crs_container div.mdl_crs_infos div.mdl_crs_more_infos div.mdl_crs_m_i div.mdl_crs_more_dtls div span{
    color: var(--plat-twelve);
    margin-left: 5px;
    margin-top: 0;
    font-size: 14px;
}

p.blocked_plan_current{
    color: var(--plat-twelve);
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 5px;
}