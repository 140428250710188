div.course{
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 3px 6px var(--shadow-color);
    transition: all .3s;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--plat-two);
    border: 1px solid var(--plat-four);
    border-top: 1px solid var(--first-color);
}

div.course:hover{
    transform: translateY(-3px);
    opacity: .95;
    cursor: pointer;
}

div.course.crs_blocked_access{
    position: relative;
    border-top: 1px solid var(--plat-twelve);
}

div.course.crs_blocked_access:hover{
    transform: none;
    opacity: 1;
}

div.course.crs_blocked_access div.big_blocked_area{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--shadow-2-color);
}

div.course.crs_blocked_access:hover{
    background: var(--plat-eleven);
}

div.course.crs_blocked_access div.big_blocked_area p{
    font-size: 18px;
    color: var(--plat-twelve);
    margin-bottom: 0;
    margin-top: 10px;
}

div.course div.crs_header{
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;
}

div.crs_header div.crs_header_left{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

div.crs_header div.crs_header_right{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

div.crs_header div.crs_thumb{
    width: 60px;
    height: 60px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px var(--shadow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .96;
    flex-shrink: 0;
    margin-right: 15px;
}

div.crs_header div.crs_thumb svg{
    fill: var(--plat-six);
}

div.crs_header div.crs_header_content{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}

div.crs_header div.crs_header_right div.crs_head_top{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

div.crs_header div.crs_header_right h3{
    color:var(--plat-six);
    font-size: 20px;
    margin-bottom: 1px;
    text-align: left;
}

div.crs_header div.crs_header_right p{
    margin-bottom: 5px !important;
    text-align: left;
    color:var(--plat-seven);
    font-size: 16px;
}

div.crs_header div.crs_header_right div.crs_middle{
    text-align: left;
}

div.crs_header div.crs_header_right span.free_courses,
div.course_header span.free_courses{
    background: var(--plat-twelve);
    color: var(--plat-six);
    padding: 1px 3px;
    border-radius: 4px;
    margin-left: 10px;
}

div.course_header span.free_courses{
    margin-left: 15px;
}

div.course div.crs_content{
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

div.crs_content div.hd_msk_bottom{
    width: inherit;
}

div.crs_content div.hd_msk_bottom ul{
    list-style-type: none;
    display: flex;
    width: inherit;
    justify-content: flex-start;
    align-items: center;
    color: var(--plat-six);
    flex-direction: row;
}

div.crs_content div.hd_msk_bottom ul li{
    display: flex;
    justify-content: center;
    align-items: center;
}

div.crs_content div.hd_msk_bottom ul li:first-child{
    margin: 0px 11px 0px 0px;
}

div.crs_content div.hd_msk_bottom ul li span{
    margin-left: 2.5px;
    font-size: 13.5px;
}

div.crs_content div.hd_msk_bottom img{
    border-radius: 50%;
    width: 26px;
    height: 26px;
    border: 2px solid var(--plat-six);
    box-shadow: 0px 3px 6px var(--shadow-color);
}

/*Media Queries*/
@media (min-width: 450px) {
    div.crs_header div.crs_thumb{
        width: 65px;
        height: 65px;
    }

    div.crs_content  div.hd_msk_bottom ul li span{
        margin-left: 3px;
        font-size: 15px;
    }

    div.crs_header div.crs_header_right h3{
        font-size: 22px;
    }

    div.crs_header div.crs_header_right p{
        font-size: 18px;
    }
}
