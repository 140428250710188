div.card_display div.card_simulator{
    width: 100%;
    height: 200px;
    background: var(--plat-three);
    border-radius: 4px;
    border: 1px solid var(--plat-eight);
    padding: 20px;
}

div.card_display div.card_simulator.card_is_default{
    background: var(--plat-gradient-color);
    border: 1px solid var(--first-color);
}

div.card_display div.card_simulator:hover{
    opacity: .95;
    cursor: pointer;
}

div.card_simulator div.cs_title{
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

div.card_simulator div.cs_title span{
    color: var(--plat-six);
    font-size: 16px;
}

div.card_simulator.card_is_default div.cs_title span{
    color: var(--first-color);
}

div.card_simulator div.cs_title span.brand{
    color: var(--plat-seven);
}

div.card_simulator div.cs_hologram_box{
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
}

div.cs_hologram_box div.cs_hologram{
    width: 50px;
    height: 28px;
    background: var(--plat-eight);
    border-radius: 4px;
    border: 1px solid var(--plat-one);
    padding: 3px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

div.cs_hologram_box div.cs_hologram div.cs_inside_hologram{
    background: var(--plat-two);
    width: 26px;
    height: 18px;
    border-radius: 4px;
    padding: 3px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

div.cs_inside_hologram div.cs_in_inside_holoragram{
    background: var(--plat-one);
    width: 100%;
    height: 10px;
    border-radius: 3px;
}

div.card_simulator div.cs_fullname{
    color: var(--plat-seven);
    font-size: 16px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

div.card_simulator div.cs_details{
    color: var(--plat-seven);
    font-size: 18px;
    display: flex;
    justify-content: space-between;
}

div.card_simulator_loader{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

div.card_simulator_loader p{
    color: var(--plat-seven);
}

/*Media Queries*/
@media (min-width: 750px) {
    div.card_display div.card_simulator{
        width: 400px;
    }
}