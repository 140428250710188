div.list_comment{
    padding: 10px;
    background: var(--plat-four);
    margin: 10px;
    border: 1px solid var(--plat-eight);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.list_comment div.box_comment{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

div.list_comment div.box_comment div.box_comm_main{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
}

div.list_comment div.box_comment div.box_comm_main h5{
    margin-right: 12px;
    font-size: 18px;
    color: var(--plat-six);
}

div.list_comment div.box_comment div.box_comm_main span{
    font-size: 14px;
    color: var(--plat-twelve);
}

div.list_comment div.box_comment p{
    margin: 0;
    font-size: 16px;
    color: var(--plat-seven);
}

p.top_comm_plat{
    color: var(--plat-seven);
}