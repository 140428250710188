div.box_all_checkout{
    width: 100%;
}

div.box_checkout_father,
div.box_checkout_default_father{
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
}

div.box_checkout_father div.box_checkout_card_payment,
div.box_checkout_father div.box_checkout_shop_summary,
div.box_checkout_default_father div.box_checkout_card_payment{
    background: var(--plat-two);
    box-shadow: 0px 3px 6px var(--shadow-color);
    border: 1px solid var(--plat-eight);
    border-top: 1px solid var(--first-color);
    width: 100%;
    border-radius: 4px;
}

div.box_checkout_father div.box_checkout_card_payment,
div.box_checkout_default_father div.box_checkout_card_payment{
    padding: 25px 15px;
}

div.box_checkout_default_father div.box_checkout_card_payment{
    margin-top: 0px;
}

div.box_checkout_father div.box_checkout_card_payment{
    margin-top: 50px;
}

div.box_checkout_father div.box_checkout_card_payment.disable_margin{
    margin-top: 0px;
}

div.box_checkout_shop_summary div.summ_head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--plat-three);
    padding: 25px 15px;
    width: 100%;
    height: 67.5px;
    box-shadow: 0px 3px 6px var(--shadow-color);
    border-bottom: 1px solid var(--plat-three);
}

div.box_checkout_shop_summary div.summ_head h2{
    color: var(--first-color);
    font-size: 18px;
    margin-bottom: 0;
}

div.box_checkout_shop_summary div.summ_content{
    padding: 25px 15px;
}

div.box_checkout_shop_summary div.summ_content div.summ_data_pay{
    display: flex;
    flex-direction: column;
}

div.summ_content div.summ_data_pay div.data_pay_line{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 18px;
}

div.summ_data_pay div.data_pay_line p{
    width: 240px;
}

div.summ_data_pay div.data_pay_line span,
div.summ_data_pay div.data_pay_line p{
    color: var(--plat-seven);
}

div.summ_data_pay div.data_pay_line p span{
    font-size: 14px;
    color: var(--plat-twelve);
}

div.summ_data_pay div.data_pay_line span.price_main,
div.summ_data_pay div.data_pay_line p.price_main{
    color: var(--plat-six);
}

div.box_checkout_shop_summary div.summ_content div.discount_coupon{
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid var(--plat-three);
}

@media (min-width: 900px) {
    div.box_checkout_father,
    div.box_checkout_default_father{
        flex-direction: row;
        align-items: flex-start;
    }

    div.box_checkout_father{
        justify-content: space-between;
    }

    div.box_checkout_default_father{
        justify-content: center;
    }

    div.box_all_checkout{
        width: 850px;
    }

    div.box_checkout_father div.box_checkout_card_payment,
    div.box_checkout_default_father div.box_checkout_card_payment{
        width: 450px;
        margin-top: 0px;
    }
    
    div.box_checkout_father div.box_checkout_shop_summary{
        width: 350px;
    }
}

@media (min-width: 990px) {
    div.box_all_checkout{
        width: 900px;
    }

    div.box_checkout_father div.box_checkout_card_payment,
    div.box_checkout_default_father div.box_checkout_card_payment{
        width: 475px;
    }
    
    div.box_checkout_father div.box_checkout_shop_summary{
        width: 375px;
    }
}

@media (min-width: 1100px) {
    div.box_all_checkout{
        width: 1000px;
    }

    div.box_checkout_father div.box_checkout_card_payment,
    div.box_checkout_default_father div.box_checkout_card_payment{
        width: 550px;
    }
    
    div.box_checkout_father div.box_checkout_shop_summary{
        width: 400px;
    }
}