.loader_box_crs{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width:100%; 
}

div.loader_crs_box_int{
  width: 100%;
  margin-bottom: 55px;
}

div.loader_crs_box_int_b{
  width: 100%;
}

.loader_crs_header{
  width:100%;
  height:185px;
  border-radius:8px;
}

.loader_crs_description{
  width: 100%;
  height:32px;
  border-radius:8px; 
  margin-top:22.5px;
}

@media (min-width: 990px) {
  .loader_box_crs{
    justify-content: space-between;
    flex-direction: row;
  }

  div.loader_crs_box_int{
    width: 350px;
    margin-bottom: 0px;
  }

  div.loader_crs_box_int_b{
    width: 400px;
  }

  .loader_crs_description:nth-child(1){
    margin-top:0px;
  }
}