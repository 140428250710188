.loader_box_home_site{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 24px 0px 0px 24px;
}

.loader_course {
    width: 100%;
    margin-bottom: 30px;
}

.loader_rectangle_crs{
    width: 100%;
    height: 170px;
    border-radius: 8px;
    margin-bottom: 12px;
}

/*Media Queries*/
@media (min-width: 900px) {
    .loader_course{
      width: 48.8%;
    }

    .loader_rectangle_crs{
        height: 190px;
    }
}
  
@media (min-width: 1536px) {
    .loader_course{
        width: 32.4%;
    }

    .loader_rectangle_crs{
        height: 180px;
    }
}