div.box_blog_art div.blog_content_article{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

div.blog_content_article h1{
    font-size: 22px;
    margin-bottom: 20px;
}

/* div.blog_content_article img{
    max-width: 100%;
    height: auto;
} */

div.blog_content_article a{
    text-decoration: none;
    color: var(--first-color);
}

div.blog_content_article a:hover{
    text-decoration: underline;
}

div.content_blog_html{
    margin-bottom: 50px;
    color: var(--plat-six);
}

div.blog_comments{
    width: 100%;
    border-radius: 3px;
    margin-bottom: 50px;
}

div.blog_comments div.blog_comm_form h2{
    color: var(--plat-six);
    font-size: 18px;
}

div.blog_comm_form{
    border-top: 1px solid var(--plat-eight);
    padding: 50px 0px 25px 0px;
}

div.blog_comm div.b_comm_title{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

div.blog_comm div.b_comm_title img{
    width: 45px;
    height: 45px;
    margin-right: 15px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px var(--shadow-color);
    border: 2px solid var(--plat-six);
}

div.blog_comm div.b_comm_tit_cont{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

div.blog_comm div.b_comm_title div.b_comm_tit_inside{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

div.blog_comm_all div.blog_comm{
    padding: 15px 0px;
}

div.blog_comm_all div.blog_comm:nth-last-child(1){
    border-bottom: 0;
}

div.blog_comm div.b_comm_title h5.usrname{
    font-size: 16px;
    margin-right: 10px;
    color: var(--plat-twelve);
}

div.blog_comm div.b_comm_title span.b_comm_date{
    color: var(--plat-seven);
    font-size: 14px;
}

div.blog_comm p.b_comm_txt{
    margin-bottom: 0;
    font-size: 18px;
    color: var(--plat-six);
}

div.blog_content_article > div.content_blog_html p,
div.blog_content_article div.blog_comm_form > p{
    margin-bottom: 30px;
    font-size: 18px;
    color: var(--plat-seven);
}

div.blog_content_article > div.content_blog_html span{
    color: var(--plat-seven) !important;
}

div.blog_content_article > div.content_blog_html h1,
div.blog_content_article > div.content_blog_html h2,
div.blog_content_article > div.content_blog_html h3,
div.blog_content_article > div.content_blog_html h4,
div.blog_content_article > div.content_blog_html h5,
div.blog_content_article > div.content_blog_html h6{
    margin-bottom: 30px;
    margin-top: 60px;
}

div.blog_content_article > div.content_blog_html p a,
div.blog_content_article > div.content_blog_html ul li a{
    margin-bottom: 10px !important;
    color: var(--plat-twelve);
}

div.blog_content_article > div.content_blog_html p a:hover,
div.blog_content_article > div.content_blog_html ul li a:hover{
    text-decoration: underline;
}

div.blog_content_article > div.content_blog_html ul{
    margin: 0px 0px 20px 40px;
}

div.blog_content_article > div.content_blog_html ul li{
    margin: 10px 0px;
    font-size: 18px;
    color: var(--plat-seven);
}

div.blog_content_article > div.content_blog_html img{
    /* width: 80%; */
    height: auto;
}

div.blog_content_article > div.content_blog_html table.tbl_imgs {
    background-color: var(--plat-two); 
    /* border: 1px solid var(--plat-four); */
    border: 1px solid var(--plat-eight);
    border-radius: 4px;
}

/* xs={12} sm={12} md={6} lg={4} xl={3} */
div.blog_content_article > div.content_blog_html img.sizexs_1{ width: 20%; }
div.blog_content_article > div.content_blog_html img.sizexs_2{ width: 40%; }
div.blog_content_article > div.content_blog_html img.sizexs_3{ width: 60%; }
div.blog_content_article > div.content_blog_html img.sizexs_4{ width: 80%; }
div.blog_content_article > div.content_blog_html img.sizexs_5{ width: 100%; }

div.blog_content_article > div.content_blog_html blockquote{
    padding: 10px 20px;
    border-left: 1px solid var(--first-color);
}

div.blog_content_article > div.content_blog_html blockquote p{
    margin-bottom: 0 !important;
}

div.box_details_single{
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--plat-two);
    border-bottom: 1px solid var(--plat-two);
    padding: 7.5px 0px;
}

div.box_details_single div.box_author{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

div.box_details_single div.box_author img{
    margin-right: 10px;
    height: 45px;
    width: 45px;
    box-shadow: 0px 3px 6px var(--shadow-color);
    border-radius: 50%;
    border: 2px solid var(--plat-six);
}

div.box_details_single div.box_author h5{
    font-size: 18px;
    font-weight: bold;
    color: var(--plat-six);
}

div.box_details_single div.box_author p.author_descp,
div.teacher_box p.author_descp{
    margin-bottom: 0px;
    font-size: 16px;
    color: var(--plat-seven);
}

div.box_alert_comments{
    background: var(--plat-four);
    border: 1px solid var(--plat-eight);
    box-shadow: 0px 3px 6px var(--shadow-color);
    padding: 50px 0px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.box_comments_form > h5{
    margin: 30px 0px 12px 0px;
    font-size: 16px;
    color: var(--plat-twelve);
}

div.box_alert_comments > p{
    margin-bottom: 0px !important;
    font-size: 18px;
    color: var(--plat-seven);
}

div.box_single_merchan{
    width: 100%;
    background: var(--plat-four);
    border: 1px solid var(--plat-eight);
    box-shadow: 0px 3px 6px var(--shadow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

div.box_single_merchan div.box_merchandise {
    background: var(--plat-four);
    max-width: 350px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--plat-seven);
}

/*Media Queries*/
@media (min-width: 541px) {
    div.blog_content_article h1{
        font-size: 23px;
    }
}

@media (min-width: 600px) {
    div.blog_content_article > div.content_blog_html img.sizesm_1{ width: 20%; }
    div.blog_content_article > div.content_blog_html img.sizesm_2{ width: 40%; }
    div.blog_content_article > div.content_blog_html img.sizesm_3{ width: 60%; }
    div.blog_content_article > div.content_blog_html img.sizesm_4{ width: 80%; }
    div.blog_content_article > div.content_blog_html img.sizesm_5{ width: 100%; }
}

@media (min-width: 650px) {
    div.blog_content_article h1{
        font-size: 22px;
    }

    div.blog_comments{
        margin-bottom: 0;
    }
}

@media (min-width: 900px) {
    div.blog_content_article > div.content_blog_html img.sizemd_1{ width: 20%; }
    div.blog_content_article > div.content_blog_html img.sizemd_2{ width: 40%; }
    div.blog_content_article > div.content_blog_html img.sizemd_3{ width: 60%; }
    div.blog_content_article > div.content_blog_html img.sizemd_4{ width: 80%; }
    div.blog_content_article > div.content_blog_html img.sizemd_5{ width: 100%; }
}

@media (min-width: 990px) {
    div.box_blog_art div.blog_content_article{
        width: calc(100% - 390px);
        margin-right: 40px;
    }

    div.blog_content_article h1{
        font-size: 24px;
    }
}

@media (min-width: 1100px) {
    div.blog_content_article h1{
        font-size: 26px;
    }
}

@media (min-width: 1200px) {
    div.blog_content_article > div.content_blog_html img.sizelg_1{ width: 20%; }
    div.blog_content_article > div.content_blog_html img.sizelg_2{ width: 40%; }
    div.blog_content_article > div.content_blog_html img.sizelg_3{ width: 60%; }
    div.blog_content_article > div.content_blog_html img.sizelg_4{ width: 80%; }
    div.blog_content_article > div.content_blog_html img.sizelg_5{ width: 100%; }
}

@media (min-width: 1300px) {
    div.blog_comments div.blog_comm_form h2{
        font-size: 23px;
    }

    div.blog_content_article h1{
        font-size: 30px;
    }
}


@media (min-width: 1536px) {
    div.blog_content_article > div.content_blog_html img.sizexl_1{ width: 20%; }
    div.blog_content_article > div.content_blog_html img.sizexl_2{ width: 40%; }
    div.blog_content_article > div.content_blog_html img.sizexl_3{ width: 60%; }
    div.blog_content_article > div.content_blog_html img.sizexl_4{ width: 80%; }
    div.blog_content_article > div.content_blog_html img.sizexl_5{ width: 100%; }
}
/* 
@media (min-width: 1500px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1700px) {
}

@media (min-width: 1800px) {
}

@media (min-width: 1900px) {
} */