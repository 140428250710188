.loader_box_articles_single{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.loader_art_center{ /*blogArtCenter*/
    width: 100%;
}

.loader_a_rectangle{
    width: 500px;
    height:32px;
    margin-bottom:15px;
    border-radius: 8px;
}

.loader_rectangle_a_mini, /*rectangleMini*/
.loader_rectangle_a_mini_b{ /*rectangleMiniB*/
  height: 32px;
  margin-bottom:50px;
  border-radius: 8px;
}

.loader_rectangle_a_mini{ /*rectangleMini*/
  width: 35%;
}

.loader_rectangle_a_mini_b{ /*rectangleMiniB*/
  width: 25%;
}

.loader_articles_single{ /*blogArticlesC*/
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

@media (min-width: 541px) {
    .loader_rectangle_a_mini{
      width: 200px;
    }
    
    .loader_rectangle_a_mini_b{
      width: 130px;
    }
}

@media (min-width: 650px) {
  .loader_art_center{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
  }
}

@media (min-width: 990px) {
    .loader_box_articles_single,
    .loader_art_center{
        margin-right: 40px;
    }
}