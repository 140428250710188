.loader_box_cat_subc{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width:100%; 
}

.loader_crs_cat_title{
  width:220px;
  height:45px;
  border-radius:8px;
}

.loader_crs_cat_description{
  width:100%;
  height:38px; 
  border-radius:8px; 
  margin-top:22.5px;
}

@media (min-width: 500px) {
  .loader_box_cat_subc{
    justify-content: flex-start;
  }

  .loader_crs_cat_title{
    width:320px;
    height:35px;
  }

  .loader_crs_cat_description{
    height:21px; 
    width: 445px;
  }
}

@media (min-width: 990px) {
  .loader_crs_cat_description{
    width:520px;
  }
}