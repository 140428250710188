.MuiBox-root .css-zxdg2z{
    width: calc(100% - 57px);
    height: 100vh;
    overflow: auto;
}

div.admin_content_center{
    max-height: 100%;
}

div.admin_con_table{
    max-height: 100%;
}